import {
  GetNextPageParamFunction,
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { useMemo } from 'react'

import { FetcherResponse, FetcherParameters, QueryFetcher } from 'api/common/types'
import { ApiRequestPaginationParameters } from 'types/api/api'

export function createUseInfiniteQuery<Fetcher extends QueryFetcher<ApiRequestPaginationParameters>, SelectorData>({
  queryKey,
  fetcher,
  selector,
  getNextPageParam,
}: {
  queryKey: string
  fetcher: Fetcher
  selector: (response?: InfiniteData<FetcherResponse<Fetcher>>) => SelectorData
  getNextPageParam: GetNextPageParamFunction<FetcherResponse<Fetcher>>
}) {
  return function useCustomInfiniteQuery(
    options: { params?: FetcherParameters<Fetcher> } & Omit<
      UseInfiniteQueryOptions<
        FetcherResponse<Fetcher>,
        unknown,
        FetcherResponse<Fetcher>,
        FetcherResponse<Fetcher>,
        [string, FetcherParameters<Fetcher>]
      >,
      'queryKey' | 'queryFn' | 'queryHash' | 'queryKeyHashFn' | 'select' | 'getNextPageParam'
    > = {},
  ) {
    const { params = {}, ...queryOptions } = options

    const { data, isLoading, ...rest } = useInfiniteQuery(
      [queryKey, params],
      ({ signal, pageParam = 0 }) =>
        fetcher({
          ...params,
          page: pageParam,
        })(signal),
      {
        ...queryOptions,
        getNextPageParam,
      },
    )

    return {
      ...rest,
      data: useMemo(() => selector(data), [data]),
      response: data,
      isLoading: options.enabled ? isLoading : rest.isFetching,
    }
  }
}
