import { WppToastContainer } from '@platform-ui-kit/components-library-react'
import { FC } from 'react'
import ReactGA from 'react-ga4'

import { gaMeasurementId } from 'app-config'
import { AppRoutes } from 'app/AppRoutes'
import { ApiProvider } from 'providers/api/ApiProvider'
import { FeatureFlagsProvider } from 'providers/flags/FlagsProvider'
import { LocalisationContextValue } from 'providers/localisation/LocalisationContext'
import { LocalisationProvider } from 'providers/localisation/LocalisationProvider'
import { StylesProvider } from 'providers/styles/StylesProvider'

interface Props {
  locale?: LocalisationContextValue
}

ReactGA.initialize(gaMeasurementId)

export const App: FC<Props> = ({ locale }) => (
  <StylesProvider>
    <WppToastContainer maxToastsToDisplay={5} />
    <ApiProvider>
      <FeatureFlagsProvider>
        <LocalisationProvider locale={locale}>
          <AppRoutes />
        </LocalisationProvider>
      </FeatureFlagsProvider>
    </ApiProvider>
  </StylesProvider>
)
