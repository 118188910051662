import { marketPlaceApi } from 'api'
import { FiltersAppType } from 'pages/catalogue/main/CatalogueFiltersContext'
import { Paginated, ApiRequestPaginationParameters, ApiRequestSortParameters } from 'types/api/api'
import { ApplicationDTO } from 'types/applications/application'

type SearchableParams = Partial<FiltersAppType> & {
  currentUserTenantId?: string
}
type Params = ApiRequestPaginationParameters & ApiRequestSortParameters<ApplicationDTO, true> & SearchableParams

export const fetchApplicationsApi = ({ page, size, sort, currentUserTenantId, ...data }: Params) =>
  marketPlaceApi.post<Paginated<ApplicationDTO>>('/applications/search', data, {
    params: {
      page,
      size,
      sort,
      currentUserTenantId,
    },
  })
