import { WppIconCross } from '@platform-ui-kit/components-library-react'
import { KeyboardEvent, useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'

import { QuickSearchResults } from 'pages/applications/list/components/QuickSearchResults'
import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

export const DEBOUNCE_SEARCH_MS = 700
export const SEARCH_QUERY_MIN_LENGTH = 3

export const ApplicationsSearch = () => {
  const { t } = useTranslation(['applications'])
  const {
    appFilters: { search, searchFieldValue },
    filtersState: { isApplicationFiltersDirty },
    setAppFilters,
  } = useCatalogueFilters()
  const [searchField, setSearchField] = useState(search || searchFieldValue)
  const [focus, setFocus] = useState(false)
  const [, cancelDebounce] = useDebounce(
    () => {
      setAppFilters(
        { searchFieldValue: searchField, ...(!searchField && !isApplicationFiltersDirty ? { search: '' } : {}) },
        false,
      )
    },
    DEBOUNCE_SEARCH_MS,
    [searchField],
  )

  useEffect(() => {
    if (!isApplicationFiltersDirty) setSearchField('')
  }, [isApplicationFiltersDirty])

  const clearSearchInput = () => {
    cancelDebounce()
    setSearchField('')
    setAppFilters({ search: '', searchFieldValue: '' }, false)
  }

  const handleSearchKeyDown = useCallback(
    ({ key }: KeyboardEvent<HTMLWppInputElement>) => {
      if (key === 'Enter') {
        setFocus(false)
        // by BA logic we have to apply filters after Enter is pressed only
        // and show detailed search results page
        setAppFilters({ searchFieldValue: searchField, search: searchField }, false)
      }
    },
    [setFocus, setAppFilters, searchField],
  )

  return (
    <S.SearchWrapper className="search" isFullwidthSearch={!!search}>
      <S.SearchInput
        value={searchField}
        onWppChange={({ detail }) => setSearchField(detail.value || '')}
        onWppFocus={() => setFocus(true)}
        // we need this timeout to prevent hiding search result immediately and correctly handle click event on search result
        onWppBlur={() => setTimeout(() => setFocus(false), 10)}
        onKeyDown={handleSearchKeyDown}
        type="search"
        placeholder={t('applications|filters.search_placeholder')}
        data-testid="products-search"
      >
        {!!searchField && <WppIconCross slot="icon-end" onClick={clearSearchInput} data-testid="icon-cross" />}
      </S.SearchInput>
      {focus && searchField.length >= 1 && searchField.length < SEARCH_QUERY_MIN_LENGTH && (
        <S.Hint className="hint" tag="p" type="s-body" data-testid="search-hint">
          {t('applications|filters.search_hint', { count: SEARCH_QUERY_MIN_LENGTH })}
        </S.Hint>
      )}
      {searchField.length >= SEARCH_QUERY_MIN_LENGTH && focus && (
        <QuickSearchResults clearSearchInput={clearSearchInput} searchField={searchField} />
      )}
    </S.SearchWrapper>
  )
}
