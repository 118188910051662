import { FullscreenAppCustomProps } from '@wpp-open/core'

import { App } from 'app/App'
import { Root } from 'app/Root'
import { UserDataProvider } from 'providers/userData/UserDataProvider'

export const AppRoot = (props: FullscreenAppCustomProps) => (
  <Root {...props}>
    <UserDataProvider>
      <App />
    </UserDataProvider>
  </Root>
)
