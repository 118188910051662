import { PropsWithChildren } from 'react'

import { InfoBanner } from 'layout/infoBanner/InfoBanner'

import * as S from 'layout/content/Content.styled'

export const Content = ({ children }: PropsWithChildren) => (
  <>
    <InfoBanner />
    <S.Wrapper direction="column" align="center">
      {children}
    </S.Wrapper>
  </>
)
