import createCache from '@emotion/cache'
import { CacheProvider, ThemeProvider } from '@emotion/react'
import { PropsWithChildren } from 'react'

import { theme } from 'providers/styles/Theme'

const cacheRtl = createCache({
  key: 'wpp',
  stylisPlugins: [
    // TODO: Enable/disable based on document dir
    // rtlPlugin as StylisPlugin
  ],
})

export const StylesProvider = ({ children }: PropsWithChildren) => (
  <CacheProvider value={cacheRtl}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </CacheProvider>
)
