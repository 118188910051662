import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const DataBannerSvg = () => (
  <S.CustomSvg width="307" height="200" viewBox="0 0 307 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M309.5 59.5C306.462 59.5 304 57.0376 304 54C304 50.9624 306.462 48.5 309.5 48.5C312.538 48.5 315 50.9624 315 54C315 57.0376 312.538 59.5 309.5 59.5Z"
      className="fillPrimary400"
    />
    <path
      d="M295 59C292.239 59 290 56.7614 290 54C290 51.2386 292.239 49 295 49C297.761 49 300 51.2386 300 54C300 56.7614 297.761 59 295 59Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M281 59C278.239 59 276 56.7614 276 54C276 51.2386 278.239 49 281 49C283.761 49 286 51.2386 286 54C286 56.7614 283.761 59 281 59Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M267 59C264.239 59 262 56.7614 262 54C262 51.2386 264.239 49 267 49C269.761 49 272 51.2386 272 54C272 56.7614 269.761 59 267 59Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M253 59C250.239 59 248 56.7614 248 54C248 51.2386 250.239 49 253 49C255.761 49 258 51.2386 258 54C258 56.7614 255.761 59 253 59Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M239 59C236.239 59 234 56.7614 234 54C234 51.2386 236.239 49 239 49C241.761 49 244 51.2386 244 54C244 56.7614 241.761 59 239 59Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M225 59C222.239 59 220 56.7614 220 54C220 51.2386 222.239 49 225 49C227.761 49 230 51.2386 230 54C230 56.7614 227.761 59 225 59Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M211.5 58.5C209.015 58.5 207 56.4853 207 54C207 51.5147 209.015 49.5 211.5 49.5C213.985 49.5 216 51.5147 216 54C216 56.4853 213.985 58.5 211.5 58.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M198.5 58.5C196.015 58.5 194 56.4853 194 54C194 51.5147 196.015 49.5 198.5 49.5C200.985 49.5 203 51.5147 203 54C203 56.4853 200.985 58.5 198.5 58.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M185.5 58.5C183.015 58.5 181 56.4853 181 54C181 51.5147 183.015 49.5 185.5 49.5C187.985 49.5 190 51.5147 190 54C190 56.4853 187.985 58.5 185.5 58.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M172.5 58.5C170.015 58.5 168 56.4853 168 54C168 51.5147 170.015 49.5 172.5 49.5C174.985 49.5 177 51.5147 177 54C177 56.4853 174.985 58.5 172.5 58.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M160 58C157.791 58 156 56.2091 156 54C156 51.7909 157.791 50 160 50C162.209 50 164 51.7909 164 54C164 56.2091 162.209 58 160 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M148 58C145.791 58 144 56.2091 144 54C144 51.7909 145.791 50 148 50C150.209 50 152 51.7909 152 54C152 56.2091 150.209 58 148 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M136 58C133.791 58 132 56.2091 132 54C132 51.7909 133.791 50 136 50C138.209 50 140 51.7909 140 54C140 56.2091 138.209 58 136 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M124 58C121.791 58 120 56.2091 120 54C120 51.7909 121.791 50 124 50C126.209 50 128 51.7909 128 54C128 56.2091 126.209 58 124 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M112 58C109.791 58 108 56.2091 108 54C108 51.7908 109.791 50 112 50C114.209 50 116 51.7908 116 54C116 56.2091 114.209 58 112 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M100 58C97.7908 58 96 56.2091 96 54C96 51.7908 97.7908 50 100 50C102.209 50 104 51.7908 104 54C104 56.2091 102.209 58 100 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M88 58C85.7908 58 84 56.2091 84 54C84 51.7908 85.7908 50 88 50C90.2092 50 92 51.7908 92 54C92 56.2091 90.2092 58 88 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M76 58C73.7908 58 72 56.2091 72 54C72 51.7908 73.7908 50 76 50C78.2092 50 80 51.7908 80 54C80 56.2091 78.2092 58 76 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M64 58C61.7908 58 60 56.2091 60 54C60 51.7908 61.7908 50 64 50C66.2092 50 68 51.7908 68 54C68 56.2091 66.2092 58 64 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M52 58C49.7908 58 48 56.2091 48 54C48 51.7908 49.7908 50 52 50C54.2092 50 56 51.7908 56 54C56 56.2091 54.2092 58 52 58Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M40 58C37.7908 58 36 56.2091 36 54C36 51.7908 37.7908 50 40 50C42.2092 50 44 51.7908 44 54C44 56.2091 42.2092 58 40 58Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M28 58C25.7908 58 24 56.2091 24 54C24 51.7908 25.7908 50 28 50C30.2092 50 32 51.7908 32 54C32 56.2091 30.2092 58 28 58Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M16 58C13.7908 58 12 56.2091 12 54C12 51.7908 13.7908 50 16 50C18.2092 50 20 51.7908 20 54C20 56.2091 18.2092 58 16 58Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M4 58C1.79081 58 0 56.2091 0 54C0 51.7908 1.79081 50 4 50C6.20919 50 8 51.7908 8 54C8 56.2091 6.20919 58 4 58Z"
      className="fillPrimary100"
    />
    <path
      d="M309.5 27.5C306.462 27.5 304 25.0376 304 22C304 18.9624 306.462 16.5 309.5 16.5C312.538 16.5 315 18.9624 315 22C315 25.0376 312.538 27.5 309.5 27.5Z"
      className="fillPrimary400"
    />
    <path
      d="M295 27C292.239 27 290 24.7614 290 22C290 19.2386 292.239 17 295 17C297.761 17 300 19.2386 300 22C300 24.7614 297.761 27 295 27Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M281 27C278.239 27 276 24.7614 276 22C276 19.2386 278.239 17 281 17C283.761 17 286 19.2386 286 22C286 24.7614 283.761 27 281 27Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M267 27C264.239 27 262 24.7614 262 22C262 19.2386 264.239 17 267 17C269.761 17 272 19.2386 272 22C272 24.7614 269.761 27 267 27Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M253 27C250.239 27 248 24.7614 248 22C248 19.2386 250.239 17 253 17C255.761 17 258 19.2386 258 22C258 24.7614 255.761 27 253 27Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M239 27C236.239 27 234 24.7614 234 22C234 19.2386 236.239 17 239 17C241.761 17 244 19.2386 244 22C244 24.7614 241.761 27 239 27Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M225 27C222.239 27 220 24.7614 220 22C220 19.2386 222.239 17 225 17C227.761 17 230 19.2386 230 22C230 24.7614 227.761 27 225 27Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M211.5 26.5C209.015 26.5 207 24.4853 207 22C207 19.5147 209.015 17.5 211.5 17.5C213.985 17.5 216 19.5147 216 22C216 24.4853 213.985 26.5 211.5 26.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M198.5 26.5C196.015 26.5 194 24.4853 194 22C194 19.5147 196.015 17.5 198.5 17.5C200.985 17.5 203 19.5147 203 22C203 24.4853 200.985 26.5 198.5 26.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M185.5 26.5C183.015 26.5 181 24.4853 181 22C181 19.5147 183.015 17.5 185.5 17.5C187.985 17.5 190 19.5147 190 22C190 24.4853 187.985 26.5 185.5 26.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M172.5 26.5C170.015 26.5 168 24.4853 168 22C168 19.5147 170.015 17.5 172.5 17.5C174.985 17.5 177 19.5147 177 22C177 24.4853 174.985 26.5 172.5 26.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M160 26C157.791 26 156 24.2091 156 22C156 19.7909 157.791 18 160 18C162.209 18 164 19.7909 164 22C164 24.2091 162.209 26 160 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M148 26C145.791 26 144 24.2091 144 22C144 19.7909 145.791 18 148 18C150.209 18 152 19.7909 152 22C152 24.2091 150.209 26 148 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M136 26C133.791 26 132 24.2091 132 22C132 19.7909 133.791 18 136 18C138.209 18 140 19.7909 140 22C140 24.2091 138.209 26 136 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M124 26C121.791 26 120 24.2091 120 22C120 19.7909 121.791 18 124 18C126.209 18 128 19.7909 128 22C128 24.2091 126.209 26 124 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M112 26C109.791 26 108 24.2091 108 22C108 19.7908 109.791 18 112 18C114.209 18 116 19.7908 116 22C116 24.2091 114.209 26 112 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M100 26C97.7908 26 96 24.2091 96 22C96 19.7908 97.7908 18 100 18C102.209 18 104 19.7908 104 22C104 24.2091 102.209 26 100 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M88 26C85.7908 26 84 24.2091 84 22C84 19.7908 85.7908 18 88 18C90.2092 18 92 19.7908 92 22C92 24.2091 90.2092 26 88 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M76 26C73.7908 26 72 24.2091 72 22C72 19.7908 73.7908 18 76 18C78.2092 18 80 19.7908 80 22C80 24.2091 78.2092 26 76 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M64 26C61.7908 26 60 24.2091 60 22C60 19.7908 61.7908 18 64 18C66.2092 18 68 19.7908 68 22C68 24.2091 66.2092 26 64 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M52 26C49.7908 26 48 24.2091 48 22C48 19.7908 49.7908 18 52 18C54.2092 18 56 19.7908 56 22C56 24.2091 54.2092 26 52 26Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M40 26C37.7908 26 36 24.2091 36 22C36 19.7908 37.7908 18 40 18C42.2092 18 44 19.7908 44 22C44 24.2091 42.2092 26 40 26Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M28 26C25.7908 26 24 24.2091 24 22C24 19.7908 25.7908 18 28 18C30.2092 18 32 19.7908 32 22C32 24.2091 30.2092 26 28 26Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M16 26C13.7908 26 12 24.2091 12 22C12 19.7908 13.7908 18 16 18C18.2092 18 20 19.7908 20 22C20 24.2091 18.2092 26 16 26Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M4 26C1.79081 26 0 24.2091 0 22C0 19.7908 1.79081 18 4 18C6.20919 18 8 19.7908 8 22C8 24.2091 6.20919 26 4 26Z"
      className="fillPrimary100"
    />
    <path
      d="M309.5 91.5C306.462 91.5 304 89.0376 304 86C304 82.9624 306.462 80.5 309.5 80.5C312.538 80.5 315 82.9624 315 86C315 89.0376 312.538 91.5 309.5 91.5Z"
      className="fillPrimary400"
    />
    <path
      d="M295 91C292.239 91 290 88.7614 290 86C290 83.2386 292.239 81 295 81C297.761 81 300 83.2386 300 86C300 88.7614 297.761 91 295 91Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M281 91C278.239 91 276 88.7614 276 86C276 83.2386 278.239 81 281 81C283.761 81 286 83.2386 286 86C286 88.7614 283.761 91 281 91Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M267 91C264.239 91 262 88.7614 262 86C262 83.2386 264.239 81 267 81C269.761 81 272 83.2386 272 86C272 88.7614 269.761 91 267 91Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M253 91C250.239 91 248 88.7614 248 86C248 83.2386 250.239 81 253 81C255.761 81 258 83.2386 258 86C258 88.7614 255.761 91 253 91Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M239 91C236.239 91 234 88.7614 234 86C234 83.2386 236.239 81 239 81C241.761 81 244 83.2386 244 86C244 88.7614 241.761 91 239 91Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M225 91C222.239 91 220 88.7614 220 86C220 83.2386 222.239 81 225 81C227.761 81 230 83.2386 230 86C230 88.7614 227.761 91 225 91Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M211.5 90.5C209.015 90.5 207 88.4853 207 86C207 83.5147 209.015 81.5 211.5 81.5C213.985 81.5 216 83.5147 216 86C216 88.4853 213.985 90.5 211.5 90.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M198.5 90.5C196.015 90.5 194 88.4853 194 86C194 83.5147 196.015 81.5 198.5 81.5C200.985 81.5 203 83.5147 203 86C203 88.4853 200.985 90.5 198.5 90.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M185.5 90.5C183.015 90.5 181 88.4853 181 86C181 83.5147 183.015 81.5 185.5 81.5C187.985 81.5 190 83.5147 190 86C190 88.4853 187.985 90.5 185.5 90.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M172.5 90.5C170.015 90.5 168 88.4853 168 86C168 83.5147 170.015 81.5 172.5 81.5C174.985 81.5 177 83.5147 177 86C177 88.4853 174.985 90.5 172.5 90.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M160 90C157.791 90 156 88.2091 156 86C156 83.7909 157.791 82 160 82C162.209 82 164 83.7909 164 86C164 88.2091 162.209 90 160 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M148 90C145.791 90 144 88.2091 144 86C144 83.7909 145.791 82 148 82C150.209 82 152 83.7909 152 86C152 88.2091 150.209 90 148 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M136 90C133.791 90 132 88.2091 132 86C132 83.7909 133.791 82 136 82C138.209 82 140 83.7909 140 86C140 88.2091 138.209 90 136 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M124 90C121.791 90 120 88.2091 120 86C120 83.7909 121.791 82 124 82C126.209 82 128 83.7909 128 86C128 88.2091 126.209 90 124 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M112 90C109.791 90 108 88.2091 108 86C108 83.7908 109.791 82 112 82C114.209 82 116 83.7908 116 86C116 88.2091 114.209 90 112 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M100 90C97.7908 90 96 88.2091 96 86C96 83.7908 97.7908 82 100 82C102.209 82 104 83.7908 104 86C104 88.2091 102.209 90 100 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M88 90C85.7908 90 84 88.2091 84 86C84 83.7908 85.7908 82 88 82C90.2092 82 92 83.7908 92 86C92 88.2091 90.2092 90 88 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M76 90C73.7908 90 72 88.2091 72 86C72 83.7908 73.7908 82 76 82C78.2092 82 80 83.7908 80 86C80 88.2091 78.2092 90 76 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M64 90C61.7908 90 60 88.2091 60 86C60 83.7908 61.7908 82 64 82C66.2092 82 68 83.7908 68 86C68 88.2091 66.2092 90 64 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M52 90C49.7908 90 48 88.2091 48 86C48 83.7908 49.7908 82 52 82C54.2092 82 56 83.7908 56 86C56 88.2091 54.2092 90 52 90Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M40 90C37.7908 90 36 88.2091 36 86C36 83.7908 37.7908 82 40 82C42.2092 82 44 83.7908 44 86C44 88.2091 42.2092 90 40 90Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M28 90C25.7908 90 24 88.2091 24 86C24 83.7908 25.7908 82 28 82C30.2092 82 32 83.7908 32 86C32 88.2091 30.2092 90 28 90Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M16 90C13.7908 90 12 88.2091 12 86C12 83.7908 13.7908 82 16 82C18.2092 82 20 83.7908 20 86C20 88.2091 18.2092 90 16 90Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M4 90C1.79081 90 0 88.2091 0 86C0 83.7908 1.79081 82 4 82C6.20919 82 8 83.7908 8 86C8 88.2091 6.20919 90 4 90Z"
      className="fillPrimary100"
    />
    <path
      d="M309.5 123.5C306.462 123.5 304 121.038 304 118C304 114.962 306.462 112.5 309.5 112.5C312.538 112.5 315 114.962 315 118C315 121.038 312.538 123.5 309.5 123.5Z"
      className="fillPrimary400"
    />
    <path
      d="M295 123C292.239 123 290 120.761 290 118C290 115.239 292.239 113 295 113C297.761 113 300 115.239 300 118C300 120.761 297.761 123 295 123Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M281 123C278.239 123 276 120.761 276 118C276 115.239 278.239 113 281 113C283.761 113 286 115.239 286 118C286 120.761 283.761 123 281 123Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M267 123C264.239 123 262 120.761 262 118C262 115.239 264.239 113 267 113C269.761 113 272 115.239 272 118C272 120.761 269.761 123 267 123Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M253 123C250.239 123 248 120.761 248 118C248 115.239 250.239 113 253 113C255.761 113 258 115.239 258 118C258 120.761 255.761 123 253 123Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M239 123C236.239 123 234 120.761 234 118C234 115.239 236.239 113 239 113C241.761 113 244 115.239 244 118C244 120.761 241.761 123 239 123Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M225 123C222.239 123 220 120.761 220 118C220 115.239 222.239 113 225 113C227.761 113 230 115.239 230 118C230 120.761 227.761 123 225 123Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M211.5 122.5C209.015 122.5 207 120.485 207 118C207 115.515 209.015 113.5 211.5 113.5C213.985 113.5 216 115.515 216 118C216 120.485 213.985 122.5 211.5 122.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M198.5 122.5C196.015 122.5 194 120.485 194 118C194 115.515 196.015 113.5 198.5 113.5C200.985 113.5 203 115.515 203 118C203 120.485 200.985 122.5 198.5 122.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M185.5 122.5C183.015 122.5 181 120.485 181 118C181 115.515 183.015 113.5 185.5 113.5C187.985 113.5 190 115.515 190 118C190 120.485 187.985 122.5 185.5 122.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M172.5 122.5C170.015 122.5 168 120.485 168 118C168 115.515 170.015 113.5 172.5 113.5C174.985 113.5 177 115.515 177 118C177 120.485 174.985 122.5 172.5 122.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M160 122C157.791 122 156 120.209 156 118C156 115.791 157.791 114 160 114C162.209 114 164 115.791 164 118C164 120.209 162.209 122 160 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M148 122C145.791 122 144 120.209 144 118C144 115.791 145.791 114 148 114C150.209 114 152 115.791 152 118C152 120.209 150.209 122 148 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M136 122C133.791 122 132 120.209 132 118C132 115.791 133.791 114 136 114C138.209 114 140 115.791 140 118C140 120.209 138.209 122 136 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M124 122C121.791 122 120 120.209 120 118C120 115.791 121.791 114 124 114C126.209 114 128 115.791 128 118C128 120.209 126.209 122 124 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M112 122C109.791 122 108 120.209 108 118C108 115.791 109.791 114 112 114C114.209 114 116 115.791 116 118C116 120.209 114.209 122 112 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M100 122C97.7908 122 96 120.209 96 118C96 115.791 97.7908 114 100 114C102.209 114 104 115.791 104 118C104 120.209 102.209 122 100 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M88 122C85.7908 122 84 120.209 84 118C84 115.791 85.7908 114 88 114C90.2092 114 92 115.791 92 118C92 120.209 90.2092 122 88 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M76 122C73.7908 122 72 120.209 72 118C72 115.791 73.7908 114 76 114C78.2092 114 80 115.791 80 118C80 120.209 78.2092 122 76 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M64 122C61.7908 122 60 120.209 60 118C60 115.791 61.7908 114 64 114C66.2092 114 68 115.791 68 118C68 120.209 66.2092 122 64 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M52 122C49.7908 122 48 120.209 48 118C48 115.791 49.7908 114 52 114C54.2092 114 56 115.791 56 118C56 120.209 54.2092 122 52 122Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M40 122C37.7908 122 36 120.209 36 118C36 115.791 37.7908 114 40 114C42.2092 114 44 115.791 44 118C44 120.209 42.2092 122 40 122Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M28 122C25.7908 122 24 120.209 24 118C24 115.791 25.7908 114 28 114C30.2092 114 32 115.791 32 118C32 120.209 30.2092 122 28 122Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M16 122C13.7908 122 12 120.209 12 118C12 115.791 13.7908 114 16 114C18.2092 114 20 115.791 20 118C20 120.209 18.2092 122 16 122Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M4 122C1.79081 122 0 120.209 0 118C0 115.791 1.79081 114 4 114C6.20919 114 8 115.791 8 118C8 120.209 6.20919 122 4 122Z"
      className="fillPrimary100"
    />
    <path
      d="M309.5 155.5C306.462 155.5 304 153.038 304 150C304 146.962 306.462 144.5 309.5 144.5C312.538 144.5 315 146.962 315 150C315 153.038 312.538 155.5 309.5 155.5Z"
      className="fillPrimary400"
    />
    <path
      d="M295 155C292.239 155 290 152.761 290 150C290 147.239 292.239 145 295 145C297.761 145 300 147.239 300 150C300 152.761 297.761 155 295 155Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M281 155C278.239 155 276 152.761 276 150C276 147.239 278.239 145 281 145C283.761 145 286 147.239 286 150C286 152.761 283.761 155 281 155Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M267 155C264.239 155 262 152.761 262 150C262 147.239 264.239 145 267 145C269.761 145 272 147.239 272 150C272 152.761 269.761 155 267 155Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M253 155C250.239 155 248 152.761 248 150C248 147.239 250.239 145 253 145C255.761 145 258 147.239 258 150C258 152.761 255.761 155 253 155Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M239 155C236.239 155 234 152.761 234 150C234 147.239 236.239 145 239 145C241.761 145 244 147.239 244 150C244 152.761 241.761 155 239 155Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M225 155C222.239 155 220 152.761 220 150C220 147.239 222.239 145 225 145C227.761 145 230 147.239 230 150C230 152.761 227.761 155 225 155Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M211.5 154.5C209.015 154.5 207 152.485 207 150C207 147.515 209.015 145.5 211.5 145.5C213.985 145.5 216 147.515 216 150C216 152.485 213.985 154.5 211.5 154.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M198.5 154.5C196.015 154.5 194 152.485 194 150C194 147.515 196.015 145.5 198.5 145.5C200.985 145.5 203 147.515 203 150C203 152.485 200.985 154.5 198.5 154.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M185.5 154.5C183.015 154.5 181 152.485 181 150C181 147.515 183.015 145.5 185.5 145.5C187.985 145.5 190 147.515 190 150C190 152.485 187.985 154.5 185.5 154.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M172.5 154.5C170.015 154.5 168 152.485 168 150C168 147.515 170.015 145.5 172.5 145.5C174.985 145.5 177 147.515 177 150C177 152.485 174.985 154.5 172.5 154.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M160 154C157.791 154 156 152.209 156 150C156 147.791 157.791 146 160 146C162.209 146 164 147.791 164 150C164 152.209 162.209 154 160 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M148 154C145.791 154 144 152.209 144 150C144 147.791 145.791 146 148 146C150.209 146 152 147.791 152 150C152 152.209 150.209 154 148 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M136 154C133.791 154 132 152.209 132 150C132 147.791 133.791 146 136 146C138.209 146 140 147.791 140 150C140 152.209 138.209 154 136 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M124 154C121.791 154 120 152.209 120 150C120 147.791 121.791 146 124 146C126.209 146 128 147.791 128 150C128 152.209 126.209 154 124 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M112 154C109.791 154 108 152.209 108 150C108 147.791 109.791 146 112 146C114.209 146 116 147.791 116 150C116 152.209 114.209 154 112 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M100 154C97.7908 154 96 152.209 96 150C96 147.791 97.7908 146 100 146C102.209 146 104 147.791 104 150C104 152.209 102.209 154 100 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M88 154C85.7908 154 84 152.209 84 150C84 147.791 85.7908 146 88 146C90.2092 146 92 147.791 92 150C92 152.209 90.2092 154 88 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M76 154C73.7908 154 72 152.209 72 150C72 147.791 73.7908 146 76 146C78.2092 146 80 147.791 80 150C80 152.209 78.2092 154 76 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M64 154C61.7908 154 60 152.209 60 150C60 147.791 61.7908 146 64 146C66.2092 146 68 147.791 68 150C68 152.209 66.2092 154 64 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M52 154C49.7908 154 48 152.209 48 150C48 147.791 49.7908 146 52 146C54.2092 146 56 147.791 56 150C56 152.209 54.2092 154 52 154Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M40 154C37.7908 154 36 152.209 36 150C36 147.791 37.7908 146 40 146C42.2092 146 44 147.791 44 150C44 152.209 42.2092 154 40 154Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M28 154C25.7908 154 24 152.209 24 150C24 147.791 25.7908 146 28 146C30.2092 146 32 147.791 32 150C32 152.209 30.2092 154 28 154Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M16 154C13.7908 154 12 152.209 12 150C12 147.791 13.7908 146 16 146C18.2092 146 20 147.791 20 150C20 152.209 18.2092 154 16 154Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M4 154C1.79081 154 0 152.209 0 150C0 147.791 1.79081 146 4 146C6.20919 146 8 147.791 8 150C8 152.209 6.20919 154 4 154Z"
      className="fillPrimary100"
    />
    <path
      d="M309.5 187.5C306.462 187.5 304 185.038 304 182C304 178.962 306.462 176.5 309.5 176.5C312.538 176.5 315 178.962 315 182C315 185.038 312.538 187.5 309.5 187.5Z"
      className="fillPrimary400"
    />
    <path
      d="M295 187C292.239 187 290 184.761 290 182C290 179.239 292.239 177 295 177C297.761 177 300 179.239 300 182C300 184.761 297.761 187 295 187Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M281 187C278.239 187 276 184.761 276 182C276 179.239 278.239 177 281 177C283.761 177 286 179.239 286 182C286 184.761 283.761 187 281 187Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M267 187C264.239 187 262 184.761 262 182C262 179.239 264.239 177 267 177C269.761 177 272 179.239 272 182C272 184.761 269.761 187 267 187Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M253 187C250.239 187 248 184.761 248 182C248 179.239 250.239 177 253 177C255.761 177 258 179.239 258 182C258 184.761 255.761 187 253 187Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M239 187C236.239 187 234 184.761 234 182C234 179.239 236.239 177 239 177C241.761 177 244 179.239 244 182C244 184.761 241.761 187 239 187Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M225 187C222.239 187 220 184.761 220 182C220 179.239 222.239 177 225 177C227.761 177 230 179.239 230 182C230 184.761 227.761 187 225 187Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M211.5 186.5C209.015 186.5 207 184.485 207 182C207 179.515 209.015 177.5 211.5 177.5C213.985 177.5 216 179.515 216 182C216 184.485 213.985 186.5 211.5 186.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M198.5 186.5C196.015 186.5 194 184.485 194 182C194 179.515 196.015 177.5 198.5 177.5C200.985 177.5 203 179.515 203 182C203 184.485 200.985 186.5 198.5 186.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M185.5 186.5C183.015 186.5 181 184.485 181 182C181 179.515 183.015 177.5 185.5 177.5C187.985 177.5 190 179.515 190 182C190 184.485 187.985 186.5 185.5 186.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M172.5 186.5C170.015 186.5 168 184.485 168 182C168 179.515 170.015 177.5 172.5 177.5C174.985 177.5 177 179.515 177 182C177 184.485 174.985 186.5 172.5 186.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M160 186C157.791 186 156 184.209 156 182C156 179.791 157.791 178 160 178C162.209 178 164 179.791 164 182C164 184.209 162.209 186 160 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M148 186C145.791 186 144 184.209 144 182C144 179.791 145.791 178 148 178C150.209 178 152 179.791 152 182C152 184.209 150.209 186 148 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M136 186C133.791 186 132 184.209 132 182C132 179.791 133.791 178 136 178C138.209 178 140 179.791 140 182C140 184.209 138.209 186 136 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M124 186C121.791 186 120 184.209 120 182C120 179.791 121.791 178 124 178C126.209 178 128 179.791 128 182C128 184.209 126.209 186 124 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M112 186C109.791 186 108 184.209 108 182C108 179.791 109.791 178 112 178C114.209 178 116 179.791 116 182C116 184.209 114.209 186 112 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M100 186C97.7908 186 96 184.209 96 182C96 179.791 97.7908 178 100 178C102.209 178 104 179.791 104 182C104 184.209 102.209 186 100 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M88 186C85.7908 186 84 184.209 84 182C84 179.791 85.7908 178 88 178C90.2092 178 92 179.791 92 182C92 184.209 90.2092 186 88 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M76 186C73.7908 186 72 184.209 72 182C72 179.791 73.7908 178 76 178C78.2092 178 80 179.791 80 182C80 184.209 78.2092 186 76 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M64 186C61.7908 186 60 184.209 60 182C60 179.791 61.7908 178 64 178C66.2092 178 68 179.791 68 182C68 184.209 66.2092 186 64 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M52 186C49.7908 186 48 184.209 48 182C48 179.791 49.7908 178 52 178C54.2092 178 56 179.791 56 182C56 184.209 54.2092 186 52 186Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M40 186C37.7908 186 36 184.209 36 182C36 179.791 37.7908 178 40 178C42.2092 178 44 179.791 44 182C44 184.209 42.2092 186 40 186Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M28 186C25.7908 186 24 184.209 24 182C24 179.791 25.7908 178 28 178C30.2092 178 32 179.791 32 182C32 184.209 30.2092 186 28 186Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M16 186C13.7908 186 12 184.209 12 182C12 179.791 13.7908 178 16 178C18.2092 178 20 179.791 20 182C20 184.209 18.2092 186 16 186Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M4 186C1.79081 186 0 184.209 0 182C0 179.791 1.79081 178 4 178C6.20919 178 8 179.791 8 182C8 184.209 6.20919 186 4 186Z"
      className="fillPrimary100"
    />
    <path
      d="M305 75C302.239 75 300 72.7614 300 70C300 67.2386 302.239 65 305 65C307.761 65 310 67.2386 310 70C310 72.7614 307.761 75 305 75Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M291 75C288.239 75 286 72.7614 286 70C286 67.2386 288.239 65 291 65C293.761 65 296 67.2386 296 70C296 72.7614 293.761 75 291 75Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M277 75C274.239 75 272 72.7614 272 70C272 67.2386 274.239 65 277 65C279.761 65 282 67.2386 282 70C282 72.7614 279.761 75 277 75Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M263 75C260.239 75 258 72.7614 258 70C258 67.2386 260.239 65 263 65C265.761 65 268 67.2386 268 70C268 72.7614 265.761 75 263 75Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M249 75C246.239 75 244 72.7614 244 70C244 67.2386 246.239 65 249 65C251.761 65 254 67.2386 254 70C254 72.7614 251.761 75 249 75Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M235 75C232.239 75 230 72.7614 230 70C230 67.2386 232.239 65 235 65C237.761 65 240 67.2386 240 70C240 72.7614 237.761 75 235 75Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M221.5 74.5C219.015 74.5 217 72.4853 217 70C217 67.5147 219.015 65.5 221.5 65.5C223.985 65.5 226 67.5147 226 70C226 72.4853 223.985 74.5 221.5 74.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M208.5 74.5C206.015 74.5 204 72.4853 204 70C204 67.5147 206.015 65.5 208.5 65.5C210.985 65.5 213 67.5147 213 70C213 72.4853 210.985 74.5 208.5 74.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M195.5 74.5C193.015 74.5 191 72.4853 191 70C191 67.5147 193.015 65.5 195.5 65.5C197.985 65.5 200 67.5147 200 70C200 72.4853 197.985 74.5 195.5 74.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M182.5 74.5C180.015 74.5 178 72.4853 178 70C178 67.5147 180.015 65.5 182.5 65.5C184.985 65.5 187 67.5147 187 70C187 72.4853 184.985 74.5 182.5 74.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M170 74C167.791 74 166 72.2091 166 70C166 67.7909 167.791 66 170 66C172.209 66 174 67.7909 174 70C174 72.2091 172.209 74 170 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M158 74C155.791 74 154 72.2091 154 70C154 67.7909 155.791 66 158 66C160.209 66 162 67.7909 162 70C162 72.2091 160.209 74 158 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M146 74C143.791 74 142 72.2091 142 70C142 67.7909 143.791 66 146 66C148.209 66 150 67.7909 150 70C150 72.2091 148.209 74 146 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M134 74C131.791 74 130 72.2091 130 70C130 67.7909 131.791 66 134 66C136.209 66 138 67.7909 138 70C138 72.2091 136.209 74 134 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M122 74C119.791 74 118 72.2091 118 70C118 67.7908 119.791 66 122 66C124.209 66 126 67.7908 126 70C126 72.2091 124.209 74 122 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M110 74C107.791 74 106 72.2091 106 70C106 67.7908 107.791 66 110 66C112.209 66 114 67.7908 114 70C114 72.2091 112.209 74 110 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M98 74C95.7908 74 94 72.2091 94 70C94 67.7908 95.7908 66 98 66C100.209 66 102 67.7908 102 70C102 72.2091 100.209 74 98 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M86 74C83.7908 74 82 72.2091 82 70C82 67.7908 83.7908 66 86 66C88.2092 66 90 67.7908 90 70C90 72.2091 88.2092 74 86 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M74 74C71.7908 74 70 72.2091 70 70C70 67.7908 71.7908 66 74 66C76.2092 66 78 67.7908 78 70C78 72.2091 76.2092 74 74 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M62 74C59.7908 74 58 72.2091 58 70C58 67.7908 59.7908 66 62 66C64.2092 66 66 67.7908 66 70C66 72.2091 64.2092 74 62 74Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M50 74C47.7908 74 46 72.2091 46 70C46 67.7908 47.7908 66 50 66C52.2092 66 54 67.7908 54 70C54 72.2091 52.2092 74 50 74Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M38 74C35.7908 74 34 72.2091 34 70C34 67.7908 35.7908 66 38 66C40.2092 66 42 67.7908 42 70C42 72.2091 40.2092 74 38 74Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M26 74C23.7908 74 22 72.2091 22 70C22 67.7908 23.7908 66 26 66C28.2092 66 30 67.7908 30 70C30 72.2091 28.2092 74 26 74Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M14 74C11.7908 74 10 72.2091 10 70C10 67.7908 11.7908 66 14 66C16.2092 66 18 67.7908 18 70C18 72.2091 16.2092 74 14 74Z"
      className="fillPrimary100"
    />
    <path
      d="M305 43C302.239 43 300 40.7614 300 38C300 35.2386 302.239 33 305 33C307.761 33 310 35.2386 310 38C310 40.7614 307.761 43 305 43Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M291 43C288.239 43 286 40.7614 286 38C286 35.2386 288.239 33 291 33C293.761 33 296 35.2386 296 38C296 40.7614 293.761 43 291 43Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M277 43C274.239 43 272 40.7614 272 38C272 35.2386 274.239 33 277 33C279.761 33 282 35.2386 282 38C282 40.7614 279.761 43 277 43Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M263 43C260.239 43 258 40.7614 258 38C258 35.2386 260.239 33 263 33C265.761 33 268 35.2386 268 38C268 40.7614 265.761 43 263 43Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M249 43C246.239 43 244 40.7614 244 38C244 35.2386 246.239 33 249 33C251.761 33 254 35.2386 254 38C254 40.7614 251.761 43 249 43Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M235 43C232.239 43 230 40.7614 230 38C230 35.2386 232.239 33 235 33C237.761 33 240 35.2386 240 38C240 40.7614 237.761 43 235 43Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M221.5 42.5C219.015 42.5 217 40.4853 217 38C217 35.5147 219.015 33.5 221.5 33.5C223.985 33.5 226 35.5147 226 38C226 40.4853 223.985 42.5 221.5 42.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M208.5 42.5C206.015 42.5 204 40.4853 204 38C204 35.5147 206.015 33.5 208.5 33.5C210.985 33.5 213 35.5147 213 38C213 40.4853 210.985 42.5 208.5 42.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M195.5 42.5C193.015 42.5 191 40.4853 191 38C191 35.5147 193.015 33.5 195.5 33.5C197.985 33.5 200 35.5147 200 38C200 40.4853 197.985 42.5 195.5 42.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M182.5 42.5C180.015 42.5 178 40.4853 178 38C178 35.5147 180.015 33.5 182.5 33.5C184.985 33.5 187 35.5147 187 38C187 40.4853 184.985 42.5 182.5 42.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M170 42C167.791 42 166 40.2091 166 38C166 35.7909 167.791 34 170 34C172.209 34 174 35.7909 174 38C174 40.2091 172.209 42 170 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M158 42C155.791 42 154 40.2091 154 38C154 35.7909 155.791 34 158 34C160.209 34 162 35.7909 162 38C162 40.2091 160.209 42 158 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M146 42C143.791 42 142 40.2091 142 38C142 35.7909 143.791 34 146 34C148.209 34 150 35.7909 150 38C150 40.2091 148.209 42 146 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M134 42C131.791 42 130 40.2091 130 38C130 35.7909 131.791 34 134 34C136.209 34 138 35.7909 138 38C138 40.2091 136.209 42 134 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M122 42C119.791 42 118 40.2091 118 38C118 35.7908 119.791 34 122 34C124.209 34 126 35.7908 126 38C126 40.2091 124.209 42 122 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M110 42C107.791 42 106 40.2091 106 38C106 35.7908 107.791 34 110 34C112.209 34 114 35.7908 114 38C114 40.2091 112.209 42 110 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M98 42C95.7908 42 94 40.2091 94 38C94 35.7908 95.7908 34 98 34C100.209 34 102 35.7908 102 38C102 40.2091 100.209 42 98 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M86 42C83.7908 42 82 40.2091 82 38C82 35.7908 83.7908 34 86 34C88.2092 34 90 35.7908 90 38C90 40.2091 88.2092 42 86 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M74 42C71.7908 42 70 40.2091 70 38C70 35.7908 71.7908 34 74 34C76.2092 34 78 35.7908 78 38C78 40.2091 76.2092 42 74 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M62 42C59.7908 42 58 40.2091 58 38C58 35.7908 59.7908 34 62 34C64.2092 34 66 35.7908 66 38C66 40.2091 64.2092 42 62 42Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M50 42C47.7908 42 46 40.2091 46 38C46 35.7908 47.7908 34 50 34C52.2092 34 54 35.7908 54 38C54 40.2091 52.2092 42 50 42Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M38 42C35.7908 42 34 40.2091 34 38C34 35.7908 35.7908 34 38 34C40.2092 34 42 35.7908 42 38C42 40.2091 40.2092 42 38 42Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M26 42C23.7908 42 22 40.2091 22 38C22 35.7908 23.7908 34 26 34C28.2092 34 30 35.7908 30 38C30 40.2091 28.2092 42 26 42Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M14 42C11.7908 42 10 40.2091 10 38C10 35.7908 11.7908 34 14 34C16.2092 34 18 35.7908 18 38C18 40.2091 16.2092 42 14 42Z"
      className="fillPrimary100"
    />
    <path
      d="M305 11C302.239 11 300 8.76142 300 6C300 3.23857 302.239 1 305 1C307.761 1 310 3.23857 310 6C310 8.76142 307.761 11 305 11Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M291 11C288.239 11 286 8.76142 286 6C286 3.23858 288.239 1 291 1C293.761 1 296 3.23858 296 6C296 8.76142 293.761 11 291 11Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M277 11C274.239 11 272 8.76142 272 6C272 3.23858 274.239 1 277 1C279.761 1 282 3.23858 282 6C282 8.76142 279.761 11 277 11Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M263 11C260.239 11 258 8.76142 258 6C258 3.23858 260.239 1 263 1C265.761 1 268 3.23858 268 6C268 8.76142 265.761 11 263 11Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M249 11C246.239 11 244 8.76142 244 6C244 3.23857 246.239 1 249 1C251.761 1 254 3.23857 254 6C254 8.76142 251.761 11 249 11Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M235 11C232.239 11 230 8.76142 230 6C230 3.23857 232.239 1 235 1C237.761 1 240 3.23857 240 6C240 8.76142 237.761 11 235 11Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M221.5 10.5C219.015 10.5 217 8.48528 217 6C217 3.51472 219.015 1.5 221.5 1.5C223.985 1.5 226 3.51472 226 6C226 8.48528 223.985 10.5 221.5 10.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M208.5 10.5C206.015 10.5 204 8.48528 204 6C204 3.51472 206.015 1.5 208.5 1.5C210.985 1.5 213 3.51472 213 6C213 8.48528 210.985 10.5 208.5 10.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M195.5 10.5C193.015 10.5 191 8.48528 191 6C191 3.51472 193.015 1.5 195.5 1.5C197.985 1.5 200 3.51472 200 6C200 8.48528 197.985 10.5 195.5 10.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M182.5 10.5C180.015 10.5 178 8.48528 178 6C178 3.51472 180.015 1.5 182.5 1.5C184.985 1.5 187 3.51472 187 6C187 8.48528 184.985 10.5 182.5 10.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M170 10C167.791 10 166 8.20914 166 6C166 3.79086 167.791 2 170 2C172.209 2 174 3.79086 174 6C174 8.20914 172.209 10 170 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M158 10C155.791 10 154 8.20914 154 6C154 3.79086 155.791 2 158 2C160.209 2 162 3.79086 162 6C162 8.20914 160.209 10 158 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M146 10C143.791 10 142 8.20914 142 6C142 3.79086 143.791 2 146 2C148.209 2 150 3.79086 150 6C150 8.20914 148.209 10 146 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M134 10C131.791 10 130 8.20914 130 6C130 3.79086 131.791 2 134 2C136.209 2 138 3.79086 138 6C138 8.20914 136.209 10 134 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M122 10C119.791 10 118 8.20913 118 5.99999C118 3.79085 119.791 2 122 2C124.209 2 126 3.79085 126 5.99999C126 8.20913 124.209 10 122 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M110 10C107.791 10 106 8.20913 106 5.99999C106 3.79085 107.791 2 110 2C112.209 2 114 3.79085 114 5.99999C114 8.20913 112.209 10 110 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M98 10C95.7908 10 94 8.20913 94 5.99999C94 3.79085 95.7908 2 98 2C100.209 2 102 3.79085 102 5.99999C102 8.20913 100.209 10 98 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M86 10C83.7908 10 82 8.20913 82 5.99999C82 3.79085 83.7908 2 86 2C88.2092 2 90 3.79085 90 5.99999C90 8.20913 88.2092 10 86 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M74 10C71.7908 10 70 8.20913 70 5.99999C70 3.79085 71.7908 2 74 2C76.2092 2 78 3.79085 78 5.99999C78 8.20913 76.2092 10 74 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M62 10C59.7908 10 58 8.20913 58 5.99999C58 3.79085 59.7908 2 62 2C64.2092 2 66 3.79085 66 5.99999C66 8.20913 64.2092 10 62 10Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M50 10C47.7908 10 46 8.20913 46 5.99999C46 3.79085 47.7908 2 50 2C52.2092 2 54 3.79085 54 5.99999C54 8.20913 52.2092 10 50 10Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M38 10C35.7908 10 34 8.20913 34 5.99999C34 3.79085 35.7908 2 38 2C40.2092 2 42 3.79085 42 5.99999C42 8.20913 40.2092 10 38 10Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M26 10C23.7908 10 22 8.20913 22 5.99999C22 3.79085 23.7908 2 26 2C28.2092 2 30 3.79085 30 5.99999C30 8.20913 28.2092 10 26 10Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M14 10C11.7908 10 10 8.20913 10 5.99999C10 3.79085 11.7908 2 14 2C16.2092 2 18 3.79085 18 5.99999C18 8.20913 16.2092 10 14 10Z"
      className="fillPrimary100"
    />
    <path
      d="M305 107C302.239 107 300 104.761 300 102C300 99.2386 302.239 97 305 97C307.761 97 310 99.2386 310 102C310 104.761 307.761 107 305 107Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M291 107C288.239 107 286 104.761 286 102C286 99.2386 288.239 97 291 97C293.761 97 296 99.2386 296 102C296 104.761 293.761 107 291 107Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M277 107C274.239 107 272 104.761 272 102C272 99.2386 274.239 97 277 97C279.761 97 282 99.2386 282 102C282 104.761 279.761 107 277 107Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M263 107C260.239 107 258 104.761 258 102C258 99.2386 260.239 97 263 97C265.761 97 268 99.2386 268 102C268 104.761 265.761 107 263 107Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M249 107C246.239 107 244 104.761 244 102C244 99.2386 246.239 97 249 97C251.761 97 254 99.2386 254 102C254 104.761 251.761 107 249 107Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M235 107C232.239 107 230 104.761 230 102C230 99.2386 232.239 97 235 97C237.761 97 240 99.2386 240 102C240 104.761 237.761 107 235 107Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M221.5 106.5C219.015 106.5 217 104.485 217 102C217 99.5147 219.015 97.5 221.5 97.5C223.985 97.5 226 99.5147 226 102C226 104.485 223.985 106.5 221.5 106.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M208.5 106.5C206.015 106.5 204 104.485 204 102C204 99.5147 206.015 97.5 208.5 97.5C210.985 97.5 213 99.5147 213 102C213 104.485 210.985 106.5 208.5 106.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M195.5 106.5C193.015 106.5 191 104.485 191 102C191 99.5147 193.015 97.5 195.5 97.5C197.985 97.5 200 99.5147 200 102C200 104.485 197.985 106.5 195.5 106.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M182.5 106.5C180.015 106.5 178 104.485 178 102C178 99.5147 180.015 97.5 182.5 97.5C184.985 97.5 187 99.5147 187 102C187 104.485 184.985 106.5 182.5 106.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M170 106C167.791 106 166 104.209 166 102C166 99.7909 167.791 98 170 98C172.209 98 174 99.7909 174 102C174 104.209 172.209 106 170 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M158 106C155.791 106 154 104.209 154 102C154 99.7909 155.791 98 158 98C160.209 98 162 99.7909 162 102C162 104.209 160.209 106 158 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M146 106C143.791 106 142 104.209 142 102C142 99.7909 143.791 98 146 98C148.209 98 150 99.7909 150 102C150 104.209 148.209 106 146 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M134 106C131.791 106 130 104.209 130 102C130 99.7909 131.791 98 134 98C136.209 98 138 99.7909 138 102C138 104.209 136.209 106 134 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M122 106C119.791 106 118 104.209 118 102C118 99.7908 119.791 98 122 98C124.209 98 126 99.7908 126 102C126 104.209 124.209 106 122 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M110 106C107.791 106 106 104.209 106 102C106 99.7908 107.791 98 110 98C112.209 98 114 99.7908 114 102C114 104.209 112.209 106 110 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M98 106C95.7908 106 94 104.209 94 102C94 99.7908 95.7908 98 98 98C100.209 98 102 99.7908 102 102C102 104.209 100.209 106 98 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M86 106C83.7908 106 82 104.209 82 102C82 99.7908 83.7908 98 86 98C88.2092 98 90 99.7908 90 102C90 104.209 88.2092 106 86 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M74 106C71.7908 106 70 104.209 70 102C70 99.7908 71.7908 98 74 98C76.2092 98 78 99.7908 78 102C78 104.209 76.2092 106 74 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M62 106C59.7908 106 58 104.209 58 102C58 99.7908 59.7908 98 62 98C64.2092 98 66 99.7908 66 102C66 104.209 64.2092 106 62 106Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M50 106C47.7908 106 46 104.209 46 102C46 99.7908 47.7908 98 50 98C52.2092 98 54 99.7908 54 102C54 104.209 52.2092 106 50 106Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M38 106C35.7908 106 34 104.209 34 102C34 99.7908 35.7908 98 38 98C40.2092 98 42 99.7908 42 102C42 104.209 40.2092 106 38 106Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M26 106C23.7908 106 22 104.209 22 102C22 99.7908 23.7908 98 26 98C28.2092 98 30 99.7908 30 102C30 104.209 28.2092 106 26 106Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M14 106C11.7908 106 10 104.209 10 102C10 99.7908 11.7908 98 14 98C16.2092 98 18 99.7908 18 102C18 104.209 16.2092 106 14 106Z"
      className="fillPrimary100"
    />
    <path
      d="M305 139C302.239 139 300 136.761 300 134C300 131.239 302.239 129 305 129C307.761 129 310 131.239 310 134C310 136.761 307.761 139 305 139Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M291 139C288.239 139 286 136.761 286 134C286 131.239 288.239 129 291 129C293.761 129 296 131.239 296 134C296 136.761 293.761 139 291 139Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M277 139C274.239 139 272 136.761 272 134C272 131.239 274.239 129 277 129C279.761 129 282 131.239 282 134C282 136.761 279.761 139 277 139Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M263 139C260.239 139 258 136.761 258 134C258 131.239 260.239 129 263 129C265.761 129 268 131.239 268 134C268 136.761 265.761 139 263 139Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M249 139C246.239 139 244 136.761 244 134C244 131.239 246.239 129 249 129C251.761 129 254 131.239 254 134C254 136.761 251.761 139 249 139Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M235 139C232.239 139 230 136.761 230 134C230 131.239 232.239 129 235 129C237.761 129 240 131.239 240 134C240 136.761 237.761 139 235 139Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M221.5 138.5C219.015 138.5 217 136.485 217 134C217 131.515 219.015 129.5 221.5 129.5C223.985 129.5 226 131.515 226 134C226 136.485 223.985 138.5 221.5 138.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M208.5 138.5C206.015 138.5 204 136.485 204 134C204 131.515 206.015 129.5 208.5 129.5C210.985 129.5 213 131.515 213 134C213 136.485 210.985 138.5 208.5 138.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M195.5 138.5C193.015 138.5 191 136.485 191 134C191 131.515 193.015 129.5 195.5 129.5C197.985 129.5 200 131.515 200 134C200 136.485 197.985 138.5 195.5 138.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M182.5 138.5C180.015 138.5 178 136.485 178 134C178 131.515 180.015 129.5 182.5 129.5C184.985 129.5 187 131.515 187 134C187 136.485 184.985 138.5 182.5 138.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M170 138C167.791 138 166 136.209 166 134C166 131.791 167.791 130 170 130C172.209 130 174 131.791 174 134C174 136.209 172.209 138 170 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M158 138C155.791 138 154 136.209 154 134C154 131.791 155.791 130 158 130C160.209 130 162 131.791 162 134C162 136.209 160.209 138 158 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M146 138C143.791 138 142 136.209 142 134C142 131.791 143.791 130 146 130C148.209 130 150 131.791 150 134C150 136.209 148.209 138 146 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M134 138C131.791 138 130 136.209 130 134C130 131.791 131.791 130 134 130C136.209 130 138 131.791 138 134C138 136.209 136.209 138 134 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M122 138C119.791 138 118 136.209 118 134C118 131.791 119.791 130 122 130C124.209 130 126 131.791 126 134C126 136.209 124.209 138 122 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M110 138C107.791 138 106 136.209 106 134C106 131.791 107.791 130 110 130C112.209 130 114 131.791 114 134C114 136.209 112.209 138 110 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M98 138C95.7908 138 94 136.209 94 134C94 131.791 95.7908 130 98 130C100.209 130 102 131.791 102 134C102 136.209 100.209 138 98 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M86 138C83.7908 138 82 136.209 82 134C82 131.791 83.7908 130 86 130C88.2092 130 90 131.791 90 134C90 136.209 88.2092 138 86 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M74 138C71.7908 138 70 136.209 70 134C70 131.791 71.7908 130 74 130C76.2092 130 78 131.791 78 134C78 136.209 76.2092 138 74 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M62 138C59.7908 138 58 136.209 58 134C58 131.791 59.7908 130 62 130C64.2092 130 66 131.791 66 134C66 136.209 64.2092 138 62 138Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M50 138C47.7908 138 46 136.209 46 134C46 131.791 47.7908 130 50 130C52.2092 130 54 131.791 54 134C54 136.209 52.2092 138 50 138Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M38 138C35.7908 138 34 136.209 34 134C34 131.791 35.7908 130 38 130C40.2092 130 42 131.791 42 134C42 136.209 40.2092 138 38 138Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M26 138C23.7908 138 22 136.209 22 134C22 131.791 23.7908 130 26 130C28.2092 130 30 131.791 30 134C30 136.209 28.2092 138 26 138Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M14 138C11.7908 138 10 136.209 10 134C10 131.791 11.7908 130 14 130C16.2092 130 18 131.791 18 134C18 136.209 16.2092 138 14 138Z"
      className="fillPrimary100"
    />
    <path
      d="M305 171C302.239 171 300 168.761 300 166C300 163.239 302.239 161 305 161C307.761 161 310 163.239 310 166C310 168.761 307.761 171 305 171Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M291 171C288.239 171 286 168.761 286 166C286 163.239 288.239 161 291 161C293.761 161 296 163.239 296 166C296 168.761 293.761 171 291 171Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M277 171C274.239 171 272 168.761 272 166C272 163.239 274.239 161 277 161C279.761 161 282 163.239 282 166C282 168.761 279.761 171 277 171Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M263 171C260.239 171 258 168.761 258 166C258 163.239 260.239 161 263 161C265.761 161 268 163.239 268 166C268 168.761 265.761 171 263 171Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M249 171C246.239 171 244 168.761 244 166C244 163.239 246.239 161 249 161C251.761 161 254 163.239 254 166C254 168.761 251.761 171 249 171Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M235 171C232.239 171 230 168.761 230 166C230 163.239 232.239 161 235 161C237.761 161 240 163.239 240 166C240 168.761 237.761 171 235 171Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M221.5 170.5C219.015 170.5 217 168.485 217 166C217 163.515 219.015 161.5 221.5 161.5C223.985 161.5 226 163.515 226 166C226 168.485 223.985 170.5 221.5 170.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M208.5 170.5C206.015 170.5 204 168.485 204 166C204 163.515 206.015 161.5 208.5 161.5C210.985 161.5 213 163.515 213 166C213 168.485 210.985 170.5 208.5 170.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M195.5 170.5C193.015 170.5 191 168.485 191 166C191 163.515 193.015 161.5 195.5 161.5C197.985 161.5 200 163.515 200 166C200 168.485 197.985 170.5 195.5 170.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M182.5 170.5C180.015 170.5 178 168.485 178 166C178 163.515 180.015 161.5 182.5 161.5C184.985 161.5 187 163.515 187 166C187 168.485 184.985 170.5 182.5 170.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M170 170C167.791 170 166 168.209 166 166C166 163.791 167.791 162 170 162C172.209 162 174 163.791 174 166C174 168.209 172.209 170 170 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M158 170C155.791 170 154 168.209 154 166C154 163.791 155.791 162 158 162C160.209 162 162 163.791 162 166C162 168.209 160.209 170 158 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M146 170C143.791 170 142 168.209 142 166C142 163.791 143.791 162 146 162C148.209 162 150 163.791 150 166C150 168.209 148.209 170 146 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M134 170C131.791 170 130 168.209 130 166C130 163.791 131.791 162 134 162C136.209 162 138 163.791 138 166C138 168.209 136.209 170 134 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M122 170C119.791 170 118 168.209 118 166C118 163.791 119.791 162 122 162C124.209 162 126 163.791 126 166C126 168.209 124.209 170 122 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M110 170C107.791 170 106 168.209 106 166C106 163.791 107.791 162 110 162C112.209 162 114 163.791 114 166C114 168.209 112.209 170 110 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M98 170C95.7908 170 94 168.209 94 166C94 163.791 95.7908 162 98 162C100.209 162 102 163.791 102 166C102 168.209 100.209 170 98 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M86 170C83.7908 170 82 168.209 82 166C82 163.791 83.7908 162 86 162C88.2092 162 90 163.791 90 166C90 168.209 88.2092 170 86 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M74 170C71.7908 170 70 168.209 70 166C70 163.791 71.7908 162 74 162C76.2092 162 78 163.791 78 166C78 168.209 76.2092 170 74 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M62 170C59.7908 170 58 168.209 58 166C58 163.791 59.7908 162 62 162C64.2092 162 66 163.791 66 166C66 168.209 64.2092 170 62 170Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M50 170C47.7908 170 46 168.209 46 166C46 163.791 47.7908 162 50 162C52.2092 162 54 163.791 54 166C54 168.209 52.2092 170 50 170Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M38 170C35.7908 170 34 168.209 34 166C34 163.791 35.7908 162 38 162C40.2092 162 42 163.791 42 166C42 168.209 40.2092 170 38 170Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M26 170C23.7908 170 22 168.209 22 166C22 163.791 23.7908 162 26 162C28.2092 162 30 163.791 30 166C30 168.209 28.2092 170 26 170Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M14 170C11.7908 170 10 168.209 10 166C10 163.791 11.7908 162 14 162C16.2092 162 18 163.791 18 166C18 168.209 16.2092 170 14 170Z"
      className="fillPrimary100"
    />
    <path
      d="M305 203C302.239 203 300 200.761 300 198C300 195.239 302.239 193 305 193C307.761 193 310 195.239 310 198C310 200.761 307.761 203 305 203Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M291 203C288.239 203 286 200.761 286 198C286 195.239 288.239 193 291 193C293.761 193 296 195.239 296 198C296 200.761 293.761 203 291 203Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M277 203C274.239 203 272 200.761 272 198C272 195.239 274.239 193 277 193C279.761 193 282 195.239 282 198C282 200.761 279.761 203 277 203Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M263 203C260.239 203 258 200.761 258 198C258 195.239 260.239 193 263 193C265.761 193 268 195.239 268 198C268 200.761 265.761 203 263 203Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M249 203C246.239 203 244 200.761 244 198C244 195.239 246.239 193 249 193C251.761 193 254 195.239 254 198C254 200.761 251.761 203 249 203Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.8"
      d="M235 203C232.239 203 230 200.761 230 198C230 195.239 232.239 193 235 193C237.761 193 240 195.239 240 198C240 200.761 237.761 203 235 203Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.7"
      d="M221.5 202.5C219.015 202.5 217 200.485 217 198C217 195.515 219.015 193.5 221.5 193.5C223.985 193.5 226 195.515 226 198C226 200.485 223.985 202.5 221.5 202.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M208.5 202.5C206.015 202.5 204 200.485 204 198C204 195.515 206.015 193.5 208.5 193.5C210.985 193.5 213 195.515 213 198C213 200.485 210.985 202.5 208.5 202.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M195.5 202.5C193.015 202.5 191 200.485 191 198C191 195.515 193.015 193.5 195.5 193.5C197.985 193.5 200 195.515 200 198C200 200.485 197.985 202.5 195.5 202.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M182.5 202.5C180.015 202.5 178 200.485 178 198C178 195.515 180.015 193.5 182.5 193.5C184.985 193.5 187 195.515 187 198C187 200.485 184.985 202.5 182.5 202.5Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M170 202C167.791 202 166 200.209 166 198C166 195.791 167.791 194 170 194C172.209 194 174 195.791 174 198C174 200.209 172.209 202 170 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M158 202C155.791 202 154 200.209 154 198C154 195.791 155.791 194 158 194C160.209 194 162 195.791 162 198C162 200.209 160.209 202 158 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M146 202C143.791 202 142 200.209 142 198C142 195.791 143.791 194 146 194C148.209 194 150 195.791 150 198C150 200.209 148.209 202 146 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.5"
      d="M134 202C131.791 202 130 200.209 130 198C130 195.791 131.791 194 134 194C136.209 194 138 195.791 138 198C138 200.209 136.209 202 134 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M122 202C119.791 202 118 200.209 118 198C118 195.791 119.791 194 122 194C124.209 194 126 195.791 126 198C126 200.209 124.209 202 122 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M110 202C107.791 202 106 200.209 106 198C106 195.791 107.791 194 110 194C112.209 194 114 195.791 114 198C114 200.209 112.209 202 110 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M98 202C95.7908 202 94 200.209 94 198C94 195.791 95.7908 194 98 194C100.209 194 102 195.791 102 198C102 200.209 100.209 202 98 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.3"
      d="M86 202C83.7908 202 82 200.209 82 198C82 195.791 83.7908 194 86 194C88.2092 194 90 195.791 90 198C90 200.209 88.2092 202 86 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.2"
      d="M74 202C71.7908 202 70 200.209 70 198C70 195.791 71.7908 194 74 194C76.2092 194 78 195.791 78 198C78 200.209 76.2092 202 74 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.1"
      d="M62 202C59.7908 202 58 200.209 58 198C58 195.791 59.7908 194 62 194C64.2092 194 66 195.791 66 198C66 200.209 64.2092 202 62 202Z"
      className="fillPrimary400"
    />
    <path
      opacity="0.6"
      d="M50 202C47.7908 202 46 200.209 46 198C46 195.791 47.7908 194 50 194C52.2092 194 54 195.791 54 198C54 200.209 52.2092 202 50 202Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M38 202C35.7908 202 34 200.209 34 198C34 195.791 35.7908 194 38 194C40.2092 194 42 195.791 42 198C42 200.209 40.2092 202 38 202Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M26 202C23.7908 202 22 200.209 22 198C22 195.791 23.7908 194 26 194C28.2092 194 30 195.791 30 198C30 200.209 28.2092 202 26 202Z"
      className="fillPrimary100"
    />
    <path
      opacity="0.6"
      d="M14 202C11.7908 202 10 200.209 10 198C10 195.791 11.7908 194 14 194C16.2092 194 18 195.791 18 198C18 200.209 16.2092 202 14 202Z"
      className="fillPrimary100"
    />
  </S.CustomSvg>
)
