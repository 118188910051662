import { useContext } from 'react'

import { FEATURE_TYPES } from 'featureFlagsConfig'
import { FlagsContext } from 'providers/flags/FlagsContext'

export const useFlag = (flagName: FEATURE_TYPES) => {
  const { flags } = useContext(FlagsContext)

  return flags[flagName]
}

export const useFlags = () => useContext(FlagsContext)
