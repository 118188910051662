import { createApi } from 'api/common/createApi'
import config, { API_TYPES } from 'app-config'

export const marketPlaceApi = createApi({
  baseURL: config[API_TYPES.WPP_MARKET_API],
})

export const tenantApi = createApi({
  baseURL: config[API_TYPES.WPP_TENANT_SERVICE_API],
})

export const masterDataApi = createApi({
  baseURL: config[API_TYPES.WPP_MASTER_DATA_API],
})

export const userDetailsProxyApi = createApi({
  baseURL: '/_apps/user-details/api',
})

export const apiInstances = [masterDataApi, marketPlaceApi, tenantApi, userDetailsProxyApi]
