import { ApplicationDTO } from 'types/applications/application'
import { Logo, LogoProps, LogoWrapper } from 'ui-base/svgIcons/logo/Logo'

interface Props extends Pick<ApplicationDTO, 'logoMeta'>, Pick<LogoProps, 'size' | 'isCircle'> {}

export const ApplicationLogoPlain = ({ logoMeta, size = 's' }: Props) => {
  const logoUrl = logoMeta.thumbnail.downloadUrl
  const isCustomLogo = !!logoMeta.thumbnail.key

  return (
    <LogoWrapper size={size}>
      <Logo
        src={logoUrl}
        size={size}
        alt="Application logo"
        data-testid={isCustomLogo ? 'product-logo-img' : 'product-category-logo'}
      />
    </LogoWrapper>
  )
}
