import * as S from 'ui-base/svgIcons/SvgIcons.styled'
type Props = JSX.IntrinsicElements['svg'] & {
  slot: string
}

export const GarageSvg = (props: Props) => (
  <S.CustomSvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M9.99992 1.6675C11.6023 1.6675 13.139 2.30403 14.272 3.43707C15.4051 4.5701 16.0416 6.10682 16.0416 7.70917C16.0416 9.45584 15.2916 11.0592 13.8224 12.4942C13.7586 12.5565 13.7089 12.6318 13.6766 12.715L13.6499 12.8008L12.7083 16.88C12.6184 17.2691 12.4066 17.6193 12.1038 17.8797C11.8011 18.14 11.4231 18.2969 11.0249 18.3275L10.8808 18.3333H9.11908C8.71979 18.3333 8.33092 18.2059 8.00911 17.9695C7.6873 17.7331 7.44935 17.4002 7.32992 17.0192L7.29242 16.88L6.35075 12.8008C6.32404 12.6843 6.26446 12.5779 6.17909 12.4942C4.77909 11.1275 4.03159 9.60834 3.96325 7.9575L3.95825 7.70917L3.96159 7.50917C4.01369 5.94246 4.6727 4.45734 5.79942 3.36747C6.92613 2.27761 8.43234 1.66834 9.99992 1.66834V1.6675ZM11.7624 15.4158H8.23659L8.51075 16.5992C8.53936 16.7223 8.60461 16.8338 8.69789 16.919C8.79117 17.0043 8.9081 17.0592 9.03325 17.0767L9.11908 17.0833H10.8808C11.0073 17.0832 11.1308 17.0446 11.235 16.9728C11.3391 16.9009 11.419 16.7991 11.4641 16.6808L11.4891 16.5983L11.7624 15.4158ZM9.99992 2.9175C8.7608 2.91746 7.56984 3.39743 6.67704 4.25668C5.78423 5.11593 5.259 6.28763 5.21159 7.52584L5.20825 7.70917L5.21409 7.94C5.27742 9.2425 5.88075 10.4567 7.05242 11.6C7.27242 11.815 7.43575 12.08 7.52825 12.3717L7.56909 12.52L7.94825 14.1658H12.0508L12.4324 12.5192C12.5012 12.2202 12.6422 11.9426 12.8433 11.7108L12.9491 11.6C14.1191 10.4558 14.7224 9.24334 14.7858 7.94L14.7916 7.70917L14.7883 7.52584C14.7411 6.28748 14.2159 5.11557 13.3231 4.25615C12.4302 3.39672 11.2392 2.91747 9.99992 2.9175Z"
      fill="#A16207"
    />
  </S.CustomSvg>
)
