import { PropsWithChildren, useMemo } from 'react'

import { FEATURE_TYPES } from 'featureFlagsConfig'
import { FlagsContext } from 'providers/flags/FlagsContext'

export const FeatureFlagsProvider = ({ children }: PropsWithChildren) => {
  const flags = useMemo(
    () => ({
      [FEATURE_TYPES.WPP_EMAIL]: false,
    }),
    [],
  )

  return (
    <FlagsContext.Provider
      value={{
        flags,
      }}
    >
      {children}
    </FlagsContext.Provider>
  )
}
