import { TreeChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppAccordion, WppIconInfo } from '@platform-ui-kit/components-library-react'
import { ComponentProps, useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { selectedItemsToData, toggledTreeState } from 'pages/applications/list/utils'
import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { Regions } from 'types/applications/enums'
import { RegionsLabel } from 'types/applications/labels'
import { Flex } from 'ui-base/flex/Flex'
import { SearchFilterBlock } from 'ui-base/searchFilterBlock/SearchFilterBlock'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

interface Props extends Omit<ComponentProps<typeof WppAccordion>, 'onChange'> {}

export const ApplicationFiltersMarkets = ({ ...rest }: Props) => {
  const {
    filtersState: { regionMarketsTreeState, isApplicationFiltersDirty },
    setFiltersState,
    appFilters: { globalRegion },
    markets: defaultMarketsList,
  } = useCatalogueFilters()
  const [search, setSearch] = useState('')
  const { t } = useTranslation(['applications'])

  useEffect(() => {
    if (!isApplicationFiltersDirty) setSearch('')
  }, [isApplicationFiltersDirty])

  const handleTreeChange = useCallback(
    (event: CustomEvent<TreeChangeEventDetail>) => {
      const { regions, markets } = selectedItemsToData(event.detail.selectedItems)
      const isGlobal = markets.length > 0 && markets.length === defaultMarketsList.length
      setFiltersState(state => ({
        ...state,
        regionMarketsTreeState: event.detail.treeState,
        isApplicationFiltersDirty: true,
        applicationFilters: {
          ...state.applicationFilters,
          ...(isGlobal ? { globalRegion: true, markets: [], regions: [] } : { globalRegion: false, markets, regions }),
        },
      }))
    },
    [setFiltersState, defaultMarketsList],
  )

  const treeStates = useMemo(() => {
    return {
      selected: toggledTreeState(regionMarketsTreeState, true),
      unselected: toggledTreeState(regionMarketsTreeState, false),
    }
  }, [regionMarketsTreeState])

  const changeGlobal = useCallback(
    (isChecked: boolean) => {
      setFiltersState(state => ({
        ...state,
        regionMarketsTreeState: isChecked ? treeStates.selected : treeStates.unselected,
        applicationFilters: {
          ...state.applicationFilters,
          globalRegion: isChecked,
        },
      }))
    },
    [setFiltersState, treeStates.selected, treeStates.unselected],
  )

  return (
    <SearchFilterBlock
      searchValue={search}
      onSearchChange={setSearch}
      nestedFilters
      filterTitle={
        <Flex gap={8}>
          <div>{t('applications|filters.market', { count: 2 })}</div>
          <S.MarketsDescriptionTooltip
            show
            text={t('applications|filters.markets_filter_description')}
            config={{ placement: 'right' }}
            data-testid="markets-filter-description-tooltip"
          >
            <WppIconInfo />
          </S.MarketsDescriptionTooltip>
        </Flex>
      }
      {...rest}
      data-testid="application-filter-regions-markets"
    >
      <S.GlobalCheckbox
        required
        labelConfig={{
          text: t(`applications|${RegionsLabel[Regions.GLOBAL]}`),
        }}
        checked={!!globalRegion}
        onWppChange={({ detail }) => changeGlobal(detail.checked)}
        data-testid="global-applications-filter"
      />

      <S.GlobalFilterDivider />

      <S.RegionMarketTree
        disableOpenCloseAnimation
        search={search}
        locales={{
          nothingFound: t('applications|filters.filter_empty_search'),
        }}
        data={regionMarketsTreeState}
        multiple
        onWppChange={handleTreeChange}
        data-testid="region-market-filter"
      />
    </SearchFilterBlock>
  )
}
