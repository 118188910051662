import React from 'react'
import ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'

import { AppRoot } from 'app/AppRoot'

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: AppRoot,
  // @ts-ignore later
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null
  },
})

export const { bootstrap, mount, unmount } = lifecycles
