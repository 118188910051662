import { ApplicationCategoryLabel } from 'types/applications/labels'
import { Leaves } from 'types/common/utils'
import { ApplicationsDictionaryTypes } from 'types/i18next'

export const applicationCategoryOptions = [
  ...Object.entries(ApplicationCategoryLabel).map(([key, value]) => ({ label: value, value: key })),
]

export const ApplicationReviewsSortingOptions: { label: Leaves<ApplicationsDictionaryTypes>; value: string }[] = [
  {
    label: 'application_reviews.reviews_sorting_options.newest_first',
    value: 'createdAt,desc',
  },
  {
    label: 'application_reviews.reviews_sorting_options.most_helpful_first',
    value: 'helpfulVoteCount,desc',
  },
  {
    label: 'application_reviews.reviews_sorting_options.highest_rating_first',
    value: 'reviewVote,desc',
  },
  {
    label: 'application_reviews.reviews_sorting_options.lowest_rating_first',
    value: 'reviewVote,asc',
  },
]
