import { useTranslation } from 'react-i18next'

import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { ApplicationCategory, ApplicationCategoryLogo } from 'types/applications/enums'
import { applicationCategoryOptions } from 'types/applications/options'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

export const ApplicationFiltersCategory = () => {
  const { t } = useTranslation(['applications'])
  const { appFilters, setAppFilters } = useCatalogueFilters()

  return (
    <S.CategoryFilter
      filterTitle={t('applications|filters.categories')}
      options={applicationCategoryOptions.map(item => ({
        ...item,
        label: t(`applications|application_categories.${item.label}.title`),
      }))}
      optionClassName="category-card"
      renderOptionContent={({ value, label }) => {
        return (
          <Flex direction="column" align="center" justify="center" gap={4}>
            <S.CategoryIcon
              src={ApplicationCategoryLogo[value as ApplicationCategory]}
              data-testid="category-card-icon"
            />
            <S.CategoryLabel type="xs-midi" data-testid="category-card-label">
              {label}
            </S.CategoryLabel>
          </Flex>
        )
      }}
      value={appFilters.categories}
      onChange={value => setAppFilters({ categories: value as ApplicationCategory[] })}
      data-testid="application-filters-categories"
    />
  )
}
