import * as S from 'ui-base/svgIcons/SvgIcons.styled'
type Props = JSX.IntrinsicElements['svg'] & {
  slot: string
}

export const CertifiedSvg = (props: Props) => (
  <S.CustomSvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M10.1908 4.70167L10.9508 6.24583L12.6542 6.49167C12.8292 6.51667 12.8983 6.73167 12.7725 6.85417L11.54 8.05417L11.8316 9.75083C11.8384 9.78993 11.834 9.83012 11.8191 9.86688C11.8042 9.90363 11.7793 9.93548 11.7472 9.95881C11.7151 9.98213 11.6771 9.99601 11.6376 9.99887C11.598 10.0017 11.5584 9.99347 11.5233 9.975L9.99998 9.17333L8.47748 9.975C8.44237 9.99347 8.4028 10.0017 8.36323 9.99887C8.32366 9.99601 8.28569 9.98213 8.2536 9.95881C8.22152 9.93548 8.1966 9.90363 8.18168 9.86688C8.16675 9.83012 8.16241 9.78993 8.16915 9.75083L8.46082 8.055L7.22748 6.855C7.19882 6.82732 7.17852 6.79214 7.16889 6.75348C7.15927 6.71481 7.1607 6.67422 7.17304 6.63633C7.18538 6.59845 7.20812 6.56479 7.23867 6.53921C7.26922 6.51363 7.30635 6.49716 7.34582 6.49167L9.04915 6.24583L9.80915 4.70167C9.82664 4.66596 9.8538 4.63588 9.88753 4.61484C9.92126 4.5938 9.96023 4.58265 9.99998 4.58265C10.0397 4.58265 10.0787 4.5938 10.1124 4.61484C10.1462 4.63588 10.1733 4.66596 10.1908 4.70167Z"
      fill="#8508E8"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M15.8333 7.5C15.8334 6.54319 15.5982 5.60103 15.1483 4.75656C14.6985 3.9121 14.0478 3.19126 13.2537 2.65757C12.4595 2.12388 11.5463 1.79373 10.5944 1.69622C9.64261 1.59871 8.68141 1.73683 7.79554 2.09841C6.90968 2.45999 6.12636 3.03393 5.51462 3.76964C4.90288 4.50534 4.48151 5.38024 4.28763 6.3172C4.09376 7.25417 4.13334 8.22444 4.40288 9.1425C4.67243 10.0606 5.16366 10.8982 5.83329 11.5817V17.7083L5.83996 17.7958C5.85493 17.9019 5.89689 18.0023 5.96183 18.0875C6.02677 18.1726 6.1125 18.2397 6.2108 18.2822C6.3091 18.3246 6.41667 18.3412 6.52319 18.3301C6.62972 18.3191 6.73162 18.2809 6.81913 18.2192L9.99913 15.98L13.1816 18.22L13.2558 18.265C13.3511 18.3138 13.4574 18.3374 13.5644 18.3333C13.6714 18.3293 13.7756 18.2978 13.8669 18.2419C13.9583 18.186 14.0338 18.1076 14.0861 18.0142C14.1384 17.9207 14.1659 17.8154 14.1658 17.7083L14.1675 11.5817C15.237 10.4925 15.8354 9.02648 15.8333 7.5ZM12.9166 12.5533V16.5042L10.3583 14.7042L10.275 14.6542C10.1743 14.6046 10.0621 14.5832 9.95018 14.592C9.8383 14.6008 9.73086 14.6395 9.63913 14.7042L7.08413 16.5042V12.5533C7.97034 13.0658 8.97626 13.3349 9.99996 13.3333C11.0625 13.3333 12.0583 13.05 12.9166 12.5533ZM5.41663 7.5C5.41663 6.28442 5.89951 5.11863 6.75905 4.25909C7.61859 3.39955 8.78438 2.91667 9.99996 2.91667C11.2155 2.91667 12.3813 3.39955 13.2409 4.25909C14.1004 5.11863 14.5833 6.28442 14.5833 7.5C14.5833 8.71557 14.1004 9.88136 13.2409 10.7409C12.3813 11.6004 11.2155 12.0833 9.99996 12.0833C8.78438 12.0833 7.61859 11.6004 6.75905 10.7409C5.89951 9.88136 5.41663 8.71557 5.41663 7.5Z"
      fill="#8508E8"
    />
  </S.CustomSvg>
)
