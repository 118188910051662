import { WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { ApplicationCategory } from 'types/applications/enums'
import { ApplicationCategoryLabel } from 'types/applications/labels'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

interface Props extends ComponentProps<typeof Flex> {
  category: ApplicationCategory
  hasNextPage?: boolean
}

export const ApplicationsSectionDesc = ({ category, hasNextPage, ...rest }: Props) => {
  const { t } = useTranslation(['applications'])
  const { setAppFilters } = useCatalogueFilters()

  return (
    <Flex align="center" {...rest}>
      <S.SectionDescriptionText direction="column">
        <Flex justify="between">
          <WppTypography tag="h2" type="2xl-heading" data-testid="category-name">
            {t(`applications|application_categories.${ApplicationCategoryLabel[category]}.title`)}
          </WppTypography>
          {hasNextPage && (
            <S.ActionButton variant="secondary" onClick={() => setAppFilters({ categories: [category] })}>
              {t('applications|filters.see_all')}
              <S.IconArrow slot="icon-end" />
            </S.ActionButton>
          )}
        </Flex>
        <S.CategoryDescription tag="p" type="m-body" data-testid="category-description">
          {t(`applications|application_categories.${ApplicationCategoryLabel[category]}.description`)}
        </S.CategoryDescription>
      </S.SectionDescriptionText>
    </Flex>
  )
}
