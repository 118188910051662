import { masterDataApi, marketPlaceApi, userDetailsProxyApi, tenantApi } from 'api'
import { ApiInstance } from 'api/common/createApi'
import { ApiErrorTypes } from 'constants/apiErrors'
import { API } from 'types/common/enums'
import { ErrorPage } from 'ui-base/errorPage/ErrorPage'

export const getApiInstance = (api: API): ApiInstance => {
  switch (api) {
    case API.MARKETPLACE:
      return marketPlaceApi

    case API.MASTER_DATA:
      return masterDataApi

    case API.CORE_FE_PROXY:
      return userDetailsProxyApi

    case API.TENANT_SERVICE:
      return tenantApi

    default:
      return null as never
  }
}

export const handleApiErrors = (error: any, styles?: Record<string, string>) => {
  const status = error?.response?.status

  if (status === 404) {
    return <ErrorPage errorType={ApiErrorTypes.NOT_FOUND} {...styles} />
  } else if (status! >= 500) {
    return <ErrorPage errorType={ApiErrorTypes.SERVER_ERROR} {...styles} />
  } else {
    return <ErrorPage errorType={ApiErrorTypes.OTHER_ERROR} {...styles} />
  }
}
