import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const SvgSomethingWentWrong = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="206" height="181" viewBox="0 0 206 181" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M102.453 160C144.284 160 178.194 126.068 178.194 84.2103C178.194 42.3529 144.284 8.42065 102.453 8.42065C60.6224 8.42065 26.712 42.3529 26.712 84.2103C26.712 126.068 60.6224 160 102.453 160Z"
      className="fillPrimary100"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.80882 47.3858C15.0824 39.4101 30.1193 37.8917 44.5162 38.369C46.5733 38.4372 48.1857 40.1612 48.1176 42.2197C48.0494 44.2782 46.3265 45.8916 44.2693 45.8234C30.4523 45.3653 17.1177 46.887 5.64602 53.78C3.88143 54.8403 1.59196 54.2685 0.532352 52.5028C-0.527259 50.737 0.0442367 48.4461 1.80882 47.3858Z"
      className="fillPrimary500"
    />
    <g filter="url(#filter0_f_209_8571)">
      <ellipse cx="84.9781" cy="73.1738" rx="45.6682" ry="7.80907" className="fillPrimary300" />
    </g>
    <g filter="url(#filter1_f_209_8571)">
      <ellipse cx="162.44" cy="72.5953" rx="20.8108" ry="7.80907" className="fillPrimary300" />
    </g>
    <g filter="url(#filter2_f_209_8571)">
      <ellipse cx="112.726" cy="151.265" rx="45.6682" ry="7.80907" className="fillPrimary300" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.468 107.982C200.527 109.748 199.956 112.039 198.191 113.099C184.919 121.074 169.875 122.593 155.479 122.116C153.421 122.048 151.809 120.324 151.877 118.265C151.945 116.207 153.668 114.593 155.725 114.661C169.542 115.119 182.884 113.597 194.354 106.705C196.119 105.645 198.408 106.216 199.468 107.982Z"
      className="fillPrimary500"
    />
    <path
      d="M97.1279 150.78H134.982C147.675 150.78 157.966 137.024 157.966 120.051C157.966 103.077 147.675 89.3163 134.982 89.3163H97.1279C104.981 89.3163 111.913 94.5799 116.055 102.635C118.72 108.059 120.106 114.022 120.106 120.067C120.106 126.111 118.72 132.074 116.055 137.498C111.913 145.532 104.981 150.78 97.1279 150.78Z"
      className="fillPrimary400"
    />
    <path
      d="M97.1279 150.78H134.982C147.675 150.78 157.966 137.024 157.966 120.051C157.966 103.077 147.675 89.3163 134.982 89.3163H97.1279C104.981 89.3163 111.913 94.5799 116.055 102.635C118.72 108.059 120.106 114.022 120.106 120.067C120.106 126.111 118.72 132.074 116.055 137.498C111.913 145.532 104.981 150.78 97.1279 150.78Z"
      fill="url(#paint0_linear_209_8571)"
    />
    <path
      d="M124.344 100.6C119.723 91.6338 111.987 85.7629 103.229 85.7629H94.7691C80.607 85.7629 69.123 101.117 69.123 120.051C69.123 138.985 80.607 154.333 94.7691 154.339H103.256C112.014 154.339 119.76 148.478 124.371 139.501C127.343 133.451 128.888 126.798 128.888 120.056C128.888 113.314 127.343 106.662 124.371 100.611L124.344 100.6Z"
      className="fillPrimary500"
    />
    <path
      d="M124.344 100.6C119.723 91.6338 111.987 85.7629 103.229 85.7629H94.7691C80.6071 85.7629 69.123 101.117 69.123 120.051C69.123 138.985 80.6071 154.333 94.7691 154.339H103.256C112.014 154.339 119.76 148.478 124.371 139.501C127.343 133.451 128.888 126.798 128.888 120.056C128.888 113.314 127.343 106.662 124.371 100.611L124.344 100.6Z"
      fill="url(#paint1_linear_209_8571)"
    />
    <path
      d="M115.789 100.6C111.178 91.6498 103.458 85.7842 94.716 85.7629C80.5646 85.7629 69.0965 101.127 69.0965 120.051C69.0965 138.974 80.5646 154.317 94.716 154.339C103.458 154.339 111.178 148.478 115.789 139.501C118.764 133.452 120.312 126.799 120.312 120.056C120.312 113.313 118.764 106.661 115.789 100.611V100.6Z"
      className="fillPrimary300"
    />
    <path
      d="M98.0936 113.729C96.8171 115.975 94.6803 117.446 92.2612 117.446H89.9244C86.0125 117.446 82.8404 113.599 82.8404 108.856C82.8404 104.113 86.0125 100.268 89.9244 100.266H92.2685C94.6877 100.266 96.8274 101.734 98.101 103.983C98.9219 105.499 99.3488 107.166 99.3488 108.855C99.3488 110.544 98.9219 112.21 98.101 113.726L98.0936 113.729Z"
      className="fillPrimary400"
    />
    <path
      d="M98.0936 136.855C96.8171 139.102 94.6803 140.573 92.2612 140.573H89.9244C86.0125 140.573 82.8404 136.726 82.8404 131.983C82.8404 127.239 86.0125 123.394 89.9244 123.393H92.2685C94.6877 123.393 96.8274 124.861 98.101 127.11C98.9219 128.626 99.3488 130.292 99.3488 131.981C99.3488 133.67 98.9219 135.337 98.101 136.853L98.0936 136.855Z"
      className="fillPrimary400"
    />
    <path
      d="M95.4828 113.729C94.2344 115.971 92.1442 117.441 89.7771 117.446C85.9455 117.446 82.8404 113.597 82.8404 108.856C82.8404 104.115 85.9455 100.272 89.7771 100.266C92.1442 100.266 94.2344 101.734 95.4828 103.983C96.2885 105.499 96.7074 107.166 96.7074 108.855C96.7074 110.544 96.2885 112.211 95.4828 113.726V113.729Z"
      className="fillPrimary400"
    />
    <path
      d="M95.4828 136.855C94.2344 139.098 92.1442 140.567 89.7771 140.573C85.9455 140.573 82.8404 136.723 82.8404 131.983C82.8404 127.242 85.9455 123.398 89.7771 123.393C92.1442 123.393 94.2344 124.861 95.4828 127.11C96.2885 128.625 96.7074 130.292 96.7074 131.981C96.7074 133.671 96.2885 135.337 95.4828 136.853V136.855Z"
      className="fillPrimary400"
    />
    <path
      d="M95.4827 113.729C94.2344 115.971 92.1441 117.441 89.7771 117.446C85.9454 117.446 82.8403 113.597 82.8403 108.856C82.8403 104.115 85.9454 100.272 89.7771 100.266C92.1441 100.266 94.2344 101.734 95.4827 103.983C96.2884 105.499 96.7074 107.166 96.7074 108.855C96.7074 110.544 96.2884 112.211 95.4827 113.726V113.729Z"
      fill="url(#paint2_linear_209_8571)"
    />
    <path
      d="M95.4827 136.855C94.2344 139.098 92.1441 140.567 89.7771 140.573C85.9454 140.573 82.8403 136.723 82.8403 131.983C82.8403 127.242 85.9454 123.398 89.7771 123.393C92.1441 123.393 94.2344 124.861 95.4827 127.11C96.2884 128.625 96.7074 130.292 96.7074 131.981C96.7074 133.671 96.2884 135.337 95.4827 136.853V136.855Z"
      fill="url(#paint3_linear_209_8571)"
    />
    <path
      d="M102.872 75.3985H65.018C52.3254 75.3985 42.0286 61.6429 42.0286 44.6695C42.0286 27.6961 52.3254 13.9352 65.018 13.9352H102.872C95.0192 13.9352 88.0872 19.1988 83.9451 27.2539C81.2286 32.6615 79.8379 38.6387 79.8882 44.6908C79.8377 50.7413 81.2284 56.7168 83.9451 62.1224C88.0872 70.135 95.0192 75.3985 102.872 75.3985Z"
      className="fillPrimary400"
    />
    <path
      d="M102.872 75.3985H65.0182C52.3256 75.3985 42.0288 61.6429 42.0288 44.6695C42.0288 27.6961 52.3256 13.9352 65.0182 13.9352H102.872C95.0194 13.9352 88.0874 19.1988 83.9453 27.2539C81.2287 32.6615 79.8381 38.6387 79.8884 44.6908C79.8379 50.7413 81.2286 56.7168 83.9453 62.1224C88.0874 70.135 95.0194 75.3985 102.872 75.3985Z"
      fill="url(#paint4_linear_209_8571)"
    />
    <path
      d="M75.6506 25.24C80.2773 16.2739 88.0131 10.403 96.7713 10.403H105.258C119.42 10.403 130.904 25.7568 130.904 44.6907C130.904 63.6247 119.42 78.9732 105.258 78.9785H96.7713C88.0131 78.9785 80.2666 73.1183 75.6506 64.1414C72.6258 58.1076 71.0766 51.441 71.1305 44.6907C71.0766 37.9405 72.6258 31.2739 75.6506 25.24Z"
      className="fillPrimary500"
    />
    <path
      d="M75.6504 25.24C80.277 16.2739 88.0129 10.403 96.771 10.403H105.258C119.42 10.403 130.904 25.7568 130.904 44.6907C130.904 63.6247 119.42 78.9732 105.258 78.9785H96.771C88.0129 78.9785 80.2664 73.1183 75.6504 64.1414C72.6256 58.1076 71.0763 51.441 71.1303 44.6907C71.0763 37.9405 72.6256 31.2739 75.6504 25.24Z"
      fill="url(#paint5_linear_209_8571)"
    />
    <path
      d="M84.206 25.24C88.822 16.2898 96.5365 10.4243 105.284 10.403C119.435 10.403 130.903 25.7675 130.903 44.6907C130.903 63.614 119.435 78.9572 105.284 78.9732C96.5365 78.9732 88.822 73.1129 84.206 64.1414C81.1811 58.1076 79.6319 51.441 79.6858 44.6907C79.6319 37.9405 81.1811 31.2739 84.206 25.24Z"
      className="fillPrimary300"
    />
    <path
      d="M107.603 40.137C111.068 40.137 113.876 36.4391 113.876 31.8775C113.876 27.3159 111.068 23.618 107.603 23.618C104.138 23.618 101.33 27.3159 101.33 31.8775C101.33 36.4391 104.138 40.137 107.603 40.137Z"
      className="fillPrimary400"
    />
    <path
      d="M107.603 66.5674C111.068 66.5674 113.876 62.8695 113.876 58.3079C113.876 53.7464 111.068 50.0485 107.603 50.0485C104.138 50.0485 101.33 53.7464 101.33 58.3079C101.33 62.8695 104.138 66.5674 107.603 66.5674Z"
      className="fillPrimary400"
    />
    <path
      d="M107.603 40.137C111.067 40.137 113.876 36.4391 113.876 31.8775C113.876 27.3159 111.067 23.618 107.603 23.618C104.138 23.618 101.33 27.3159 101.33 31.8775C101.33 36.4391 104.138 40.137 107.603 40.137Z"
      fill="url(#paint7_linear_209_8571)"
    />
    <path
      d="M107.603 66.5674C111.067 66.5674 113.876 62.8695 113.876 58.3079C113.876 53.7464 111.067 50.0485 107.603 50.0485C104.138 50.0485 101.33 53.7464 101.33 58.3079C101.33 62.8695 104.138 66.5674 107.603 66.5674Z"
      fill="url(#paint8_linear_209_8571)"
    />
    <path
      d="M32.0645 94.0769C32.5818 91.9325 35.6321 91.9325 36.1494 94.0769L36.5037 95.5456C36.6885 96.3118 37.2867 96.9101 38.0528 97.0952L39.5258 97.451C41.6695 97.9688 41.6695 101.018 39.5258 101.536L38.0528 101.891C37.2867 102.076 36.6885 102.675 36.5037 103.441L36.1494 104.91C35.6321 107.054 32.5818 107.054 32.0645 104.91L31.7102 103.441C31.5254 102.675 30.9272 102.076 30.1611 101.891L28.6881 101.536C26.5444 101.018 26.5444 97.9688 28.6881 97.451L30.1611 97.0952C30.9272 96.9101 31.5254 96.3118 31.7102 95.5456L32.0645 94.0769Z"
      className="fillPrimary400"
    />
    <path
      d="M107.488 38.8198C110.255 38.8198 112.498 35.7048 112.498 31.8621C112.498 28.0195 110.255 24.9044 107.488 24.9044C104.721 24.9044 102.478 28.0195 102.478 31.8621C102.478 35.7048 104.721 38.8198 107.488 38.8198Z"
      className="fillPrimary400"
    />
    <path
      d="M107.488 65.2501C110.255 65.2501 112.498 62.1351 112.498 58.2924C112.498 54.4498 110.255 51.3347 107.488 51.3347C104.721 51.3347 102.478 54.4498 102.478 58.2924C102.478 62.1351 104.721 65.2501 107.488 65.2501Z"
      className="fillPrimary400"
    />
    <path
      d="M147.102 8.46646C147.619 6.32204 150.67 6.32204 151.187 8.46646L151.541 9.9351C151.726 10.7013 152.324 11.2996 153.09 11.4847L154.563 11.8405C156.707 12.3583 156.707 15.4073 154.563 15.9251L153.09 16.2809C152.324 16.466 151.726 17.0643 151.541 17.8305L151.187 19.2991C150.67 21.4435 147.619 21.4435 147.102 19.2991L146.748 17.8305C146.563 17.0643 145.965 16.466 145.199 16.2809L143.726 15.9251C141.582 15.4073 141.582 12.3583 143.726 11.8405L145.199 11.4847C145.965 11.2996 146.563 10.7013 146.748 9.9351L147.102 8.46646Z"
      className="fillPrimary400"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.1072 67.6786C35.3843 67.6786 36.4196 66.6427 36.4196 65.3648C36.4196 64.0869 35.3843 63.051 34.1072 63.051C32.8302 63.051 31.7949 64.0869 31.7949 65.3648C31.7949 66.6427 32.8302 67.6786 34.1072 67.6786ZM34.1072 69.414C36.3421 69.414 38.1538 67.6011 38.1538 65.3648C38.1538 63.1285 36.3421 61.3157 34.1072 61.3157C31.8724 61.3157 30.0607 63.1285 30.0607 65.3648C30.0607 67.6011 31.8724 69.414 34.1072 69.414Z"
      className="fillPrimary300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.467 141.649C166.744 141.649 167.779 140.613 167.779 139.336C167.779 138.058 166.744 137.022 165.467 137.022C164.19 137.022 163.154 138.058 163.154 139.336C163.154 140.613 164.19 141.649 165.467 141.649ZM165.467 143.385C167.702 143.385 169.513 141.572 169.513 139.336C169.513 137.099 167.702 135.286 165.467 135.286C163.232 135.286 161.42 137.099 161.42 139.336C161.42 141.572 163.232 143.385 165.467 143.385Z"
      className="fillPrimary300"
    />
    <g filter="url(#filter3_f_209_8571)">
      <ellipse cx="163" cy="70" rx="21" ry="5" className="fillDanger300" />
    </g>
    <path
      d="M162.852 88.5507C162.452 86.9829 163.899 85.5704 165.455 86.0081L168.09 86.7489C169.647 87.1866 170.146 89.1463 168.989 90.2763L167.03 92.1891C165.873 93.3191 163.928 92.772 163.528 91.2043L162.852 88.5507Z"
      className="fillPrimary300"
    />
    <path
      d="M56.7718 143.289C58.3391 142.891 59.7488 144.341 59.3094 145.898L58.5656 148.533C58.1262 150.09 56.1671 150.587 55.0393 149.428L53.1303 147.466C52.0025 146.306 52.5518 144.36 54.1191 143.962L56.7718 143.289Z"
      className="fillPrimary300"
    />
    <path
      d="M183.046 54.4554C182.492 58.6336 180.888 62.603 178.385 65.9925C175.881 69.382 172.56 72.0809 168.731 73.8371C164.902 75.5932 160.69 76.3491 156.49 76.0341C152.289 75.7192 148.238 74.3437 144.713 72.0362L135.129 75.3019C134.839 75.3992 134.526 75.4107 134.229 75.3349C133.931 75.2591 133.662 75.0993 133.453 74.8745C133.245 74.6498 133.105 74.3697 133.051 74.0675C132.997 73.7654 133.031 73.4542 133.149 73.1709L137.004 63.965C134.965 60.5502 133.772 56.6968 133.526 52.7266C133.28 48.7565 133.987 44.7851 135.588 41.1443C137.19 37.5036 139.639 34.2992 142.73 31.7991C145.822 29.299 149.467 27.5757 153.361 26.7734C157.254 25.971 161.283 26.1129 165.111 27.1871C168.938 28.2614 172.453 30.2367 175.362 32.9482C178.27 35.6596 180.488 39.0283 181.829 42.7727C183.171 46.5171 183.597 50.5284 183.073 54.4714L183.046 54.4554Z"
      className="fillDanger400"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.9439 6.85452C82.221 6.85452 83.2563 5.8186 83.2563 4.54072C83.2563 3.26285 82.221 2.22693 80.9439 2.22693C79.6669 2.22693 78.6316 3.26285 78.6316 4.54072C78.6316 5.8186 79.6669 6.85452 80.9439 6.85452ZM80.9439 8.58987C83.1788 8.58987 84.9905 6.77701 84.9905 4.54072C84.9905 2.30444 83.1788 0.491577 80.9439 0.491577C78.7091 0.491577 76.8974 2.30444 76.8974 4.54072C76.8974 6.77701 78.7091 8.58987 80.9439 8.58987Z"
      className="fillPrimary300"
    />
    <path
      d="M158.153 64.1335C160.475 64.1335 162.096 62.5478 162.096 60.3353C162.096 58.0858 160.475 56.5001 158.153 56.5001C155.868 56.5001 154.21 58.0858 154.21 60.3353C154.21 62.5478 155.868 64.1335 158.153 64.1335ZM161.764 37.0664H154.541V44.2941L156.384 54.2876H159.885L161.764 44.2941V37.0664Z"
      className="fillWhite"
    />
    <rect x="61.7097" y="102.909" width="33.0167" height="11.8937" rx="5.94683" className="fillPrimary400" />
    <rect x="61.7097" y="126.036" width="33.0167" height="11.8936" rx="5.94682" className="fillPrimary400" />
    <rect x="61.7095" y="102.909" width="33.0167" height="11.8937" rx="5.94683" fill="url(#paint9_linear_209_8571)" />
    <rect x="61.7095" y="126.036" width="33.0167" height="11.8936" rx="5.94682" fill="url(#paint10_linear_209_8571)" />
    <defs>
      <filter
        id="filter0_f_209_8571"
        x="17.4241"
        y="43.4789"
        width="135.108"
        height="59.3898"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.9429" result="effect1_foregroundBlur_209_8571" />
      </filter>
      <filter
        id="filter1_f_209_8571"
        x="119.857"
        y="43.0133"
        width="85.1676"
        height="59.1642"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_209_8571" />
      </filter>
      <filter
        id="filter2_f_209_8571"
        x="45.2845"
        y="121.683"
        width="134.882"
        height="59.1642"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_209_8571" />
      </filter>
      <filter
        id="filter3_f_209_8571"
        x="126"
        y="49"
        width="74"
        height="42"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_209_8571" />
      </filter>
      <linearGradient
        id="paint0_linear_209_8571"
        x1="108.5"
        y1="75.5"
        x2="127.547"
        y2="150.78"
        gradientUnits="userSpaceOnUse"
      >
        <stop className="stopColorWhite" stopOpacity="0.72" />
        <stop offset="1" className="stopColorWhite" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_209_8571"
        x1="132.5"
        y1="86"
        x2="114.99"
        y2="147.997"
        gradientUnits="userSpaceOnUse"
      >
        <stop className="stopColorWhite" stopOpacity="0.48" />
        <stop offset="1" className="stopColorWhite" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_209_8571"
        x1="78.6076"
        y1="117.049"
        x2="100.687"
        y2="98.0536"
        gradientUnits="userSpaceOnUse"
      >
        <stop className="stopColorWhite" stopOpacity="0.56" />
        <stop offset="0.672954" className="stopColorWhite" stopOpacity="0.16" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_209_8571"
        x1="78.6076"
        y1="140.175"
        x2="100.687"
        y2="121.18"
        gradientUnits="userSpaceOnUse"
      >
        <stop className="stopColorWhite" stopOpacity="0.56" />
        <stop offset="0.672954" className="stopColorWhite" stopOpacity="0.16" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_209_8571"
        x1="72.4506"
        y1="13.9352"
        x2="72.4506"
        y2="75.3985"
        gradientUnits="userSpaceOnUse"
      >
        <stop className="stopColorWhite" stopOpacity="0.72" />
        <stop offset="1" className="stopColorWhite" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_209_8571"
        x1="101.016"
        y1="10.403"
        x2="62.5"
        y2="52.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop className="stopColorWhite" stopOpacity="0.48" />
        <stop offset="1" className="stopColorWhite" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint7_linear_209_8571" x1="97.5" y1="24" x2="118.5" y2="41" gradientUnits="userSpaceOnUse">
        <stop className="stopColorWhite" stopOpacity="0.56" />
        <stop offset="1" className="stopColorWhite" stopOpacity="0.16" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_209_8571"
        x1="97.5"
        y1="50.4304"
        x2="118.5"
        y2="67.4304"
        gradientUnits="userSpaceOnUse"
      >
        <stop className="stopColorWhite" stopOpacity="0.56" />
        <stop offset="1" className="stopColorWhite" stopOpacity="0.16" />
      </linearGradient>
      <linearGradient id="paint9_linear_209_8571" x1="66.5" y1="109" x2="95" y2="123" gradientUnits="userSpaceOnUse">
        <stop className="stopColorWhite" stopOpacity="0.56" />
        <stop offset="1" className="stopColorWhite" stopOpacity="0.16" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_209_8571"
        x1="66.5"
        y1="132.127"
        x2="95"
        y2="146.127"
        gradientUnits="userSpaceOnUse"
      >
        <stop className="stopColorWhite" stopOpacity="0.56" />
        <stop offset="1" className="stopColorWhite" stopOpacity="0.16" />
      </linearGradient>
    </defs>
  </S.CustomSvg>
)
