import { useOs } from '@wpp-open/react'

import { useInfiniteApplicationsApi } from 'api/applications/queries/useInfiniteApplicationsApi'
import { PageSizes } from 'constants/pageSizes'
import { useApplicationSearch } from 'pages/applications/list/useApplicationSearch'
import {
  AllCategoriesRequest,
  AppCategoryRequests,
  applicationsTypesByRenderingOrder,
  AppsQueriesMap,
  getResultsNumber,
} from 'pages/applications/list/utils'
import { ApplicationCategory } from 'types/applications/enums'

export const useApplicationsQuery = () => {
  const {
    osContext: {
      tenant: { azId: currentUserTenantId },
    },
  } = useOs()

  const { hasFilters, queryFilters, isSearchApplied } = useApplicationSearch()

  const appsQueriesMap: AppsQueriesMap = {
    [ApplicationCategory.BRAND]: useInfiniteApplicationsApi({
      params: {
        categories: [ApplicationCategory.BRAND],
        size: PageSizes.PAGINATED_APPLICATIONS_SECTION_SIZE,
        currentUserTenantId,
      },
      enabled: !hasFilters,
    }),
    [ApplicationCategory.CREATIVE]: useInfiniteApplicationsApi({
      params: {
        categories: [ApplicationCategory.CREATIVE],
        size: PageSizes.PAGINATED_APPLICATIONS_SECTION_SIZE,
        currentUserTenantId,
      },
      enabled: !hasFilters,
    }),
    [ApplicationCategory.MEDIA]: useInfiniteApplicationsApi({
      params: {
        categories: [ApplicationCategory.MEDIA],
        size: PageSizes.PAGINATED_APPLICATIONS_SECTION_SIZE,
        currentUserTenantId,
      },
      enabled: !hasFilters,
    }),
    [ApplicationCategory.DATA]: useInfiniteApplicationsApi({
      params: {
        categories: [ApplicationCategory.DATA],
        size: PageSizes.PAGINATED_APPLICATIONS_SECTION_SIZE,
        currentUserTenantId,
      },
      enabled: !hasFilters,
    }),
    [ApplicationCategory.COMMERCE]: useInfiniteApplicationsApi({
      params: {
        categories: [ApplicationCategory.COMMERCE],
        size: PageSizes.PAGINATED_APPLICATIONS_SECTION_SIZE,
        currentUserTenantId,
      },
      enabled: !hasFilters,
    }),
    [ApplicationCategory.EXPERIENCE]: useInfiniteApplicationsApi({
      params: {
        categories: [ApplicationCategory.EXPERIENCE],
        size: PageSizes.PAGINATED_APPLICATIONS_SECTION_SIZE,
        currentUserTenantId,
      },
      enabled: !hasFilters,
    }),
    [ApplicationCategory.OTHER_APPS]: useInfiniteApplicationsApi({
      params: {
        categories: [ApplicationCategory.OTHER_APPS],
        size: PageSizes.PAGINATED_APPLICATIONS_SECTION_SIZE,
        currentUserTenantId,
      },
      enabled: !hasFilters,
    }),
    [ApplicationCategory.PR]: useInfiniteApplicationsApi({
      params: {
        categories: [ApplicationCategory.PR],
        size: PageSizes.PAGINATED_APPLICATIONS_SECTION_SIZE,
        currentUserTenantId,
      },
      enabled: !hasFilters,
    }),
    [ApplicationCategory.PRODUCTION]: useInfiniteApplicationsApi({
      params: {
        categories: [ApplicationCategory.PRODUCTION],
        size: PageSizes.PAGINATED_APPLICATIONS_SECTION_SIZE,
        currentUserTenantId,
      },
      enabled: !hasFilters,
    }),
    [AllCategoriesRequest]: useInfiniteApplicationsApi({
      params: {
        size: PageSizes.PAGINATED_APPLICATIONS_EXPANDED_SIZE,
        currentUserTenantId,
        ...queryFilters,
      },
      enabled: hasFilters,
    }),
  }

  const visibleProductTypes: AppCategoryRequests[] = hasFilters
    ? [AllCategoriesRequest]
    : applicationsTypesByRenderingOrder

  const visibleAppsQueriesMap = visibleProductTypes.reduce(
    (acc, type) => ({ ...acc, [type]: appsQueriesMap[type] }),
    {} as AppsQueriesMap,
  )

  const appsQueriesValues = Object.values(visibleAppsQueriesMap)

  const isError = appsQueriesValues.some(({ isError }) => isError)
  const isLoading = appsQueriesValues.some(({ isLoading }) => isLoading)
  const resultsNumber = getResultsNumber(appsQueriesValues)

  return {
    isError,
    isLoading,
    resultsNumber,
    appsQueriesValues,
    appsQueriesMap: visibleAppsQueriesMap,
    hasFilters,
    isSearchApplied,
  }
}
