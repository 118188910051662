import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ApplicationAvailabilityFilter } from 'pages/applications/list/components/ApplicationAvailabilityFilter'
import { ApplicationCheckboxFilter } from 'pages/applications/list/components/ApplicationCheckboxFilter'
import { ApplicationCommercialModel } from 'pages/applications/list/components/ApplicationCommercialModel'
import { ApplicationFiltersCategory } from 'pages/applications/list/components/ApplicationFiltersCategory'
import { ApplicationFiltersMarkets } from 'pages/applications/list/components/ApplicationFiltersMarkets'
import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'

import * as S from 'pages/applications/list/components/applicationsFilters/ApplicationsFilters.styled'

export const ApplicationsFilters = () => {
  const { applicationTenants } = useCatalogueFilters()
  const { t } = useTranslation(['applications'])

  const tenantList = useMemo(
    () =>
      Object.values(applicationTenants)
        .map(option => ({
          label: option.name,
          value: option.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [applicationTenants],
  )

  const { appFilters, setAppFilters } = useCatalogueFilters()
  const { tenantIds } = appFilters

  return (
    <S.FiltersWrapper>
      <ApplicationFiltersCategory />
      <ApplicationCommercialModel />

      <ApplicationFiltersMarkets />

      <ApplicationAvailabilityFilter />

      <ApplicationCheckboxFilter
        data-testid="application-filter-created-on-workspace"
        hasSearch
        filterTitle={t('applications|filters.created_on_workspace')}
        options={tenantList}
        value={tenantIds}
        onChange={value => setAppFilters({ tenantIds: value })}
      />
    </S.FiltersWrapper>
  )
}
