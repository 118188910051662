import { Component, PropsWithChildren, ReactNode } from 'react'

interface Props {
  fallback: ReactNode
}

interface State {
  hasError: boolean
}

export class BaseErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  state: State = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}
