import styled from '@emotion/styled'
import { WppIconCross, WppIconInfo, WppTypography } from '@platform-ui-kit/components-library-react'

export const InfoBanner = styled('div')`
  height: var(--wpp-marketplace-header-height);
  background: var(--wpp-grey-color-700);
  display: flex;
  padding: 0 30px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const IconCross = styled(WppIconCross)`
  color: var(--wpp-grey-color-000);
`

export const IconInfo = styled(WppIconInfo)`
  color: var(--wpp-grey-color-600);
`

export const BannerText = styled(WppTypography)`
  &::part(typography) {
    color: var(--wpp-grey-color-000);
  }
`
