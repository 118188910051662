import { createContext } from 'react'

export interface MarketplaceSizeState {
  width: number
  height: number
}

export type MarketplaceSizeContextType = MarketplaceSizeState

export const MarketplaceSizeContext = createContext<MarketplaceSizeContextType>(null!)
