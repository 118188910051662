import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'

import { CancelableRequest } from 'api/common/types'

export class ApiInstance {
  client: AxiosInstance

  constructor(config?: AxiosRequestConfig) {
    const client = axios.create(config)

    this.client = client
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): CancelableRequest<R> {
    return (signal?: AbortSignal) =>
      this.client.get(url, {
        ...config,
        signal,
      })
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): CancelableRequest<R> {
    return (signal?: AbortSignal) =>
      this.client.post(url, data, {
        ...config,
        signal,
      })
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): CancelableRequest<R> {
    return (signal?: AbortSignal) =>
      this.client.put(url, data, {
        ...config,
        signal,
      })
  }

  patch<T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): CancelableRequest<R> {
    return (signal?: AbortSignal) =>
      this.client.patch(url, data, {
        ...config,
        signal,
      })
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): CancelableRequest<R> {
    return (signal?: AbortSignal) =>
      this.client.delete(url, {
        ...config,
        signal,
      })
  }
}

export const createApi = (config?: AxiosRequestConfig) => new ApiInstance(config)
