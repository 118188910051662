import { WppTag, WppTooltip } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { ApplicationDTO } from 'types/applications/application'
import { Flex } from 'ui-base/flex/Flex'

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  isAvailable: ApplicationDTO['isAvailable']
}

export const ApplicationCardActions = ({ isAvailable, children, ...rest }: Props) => {
  const { t } = useTranslation(['applications'])

  return (
    <div {...rest}>
      <Flex gap={isAvailable && !!children ? 4 : 0} direction="row" align="center">
        {isAvailable && (
          <WppTooltip text={t('applications|available_tooltip')} config={{ placement: 'right' }}>
            <WppTag label={t('applications|available')} variant="positive" data-testid="label-available" />
          </WppTooltip>
        )}
        {children}
      </Flex>
    </div>
  )
}
