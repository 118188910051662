import { WppToggle } from '@platform-ui-kit/components-library-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { Flex } from 'ui-base/flex/Flex'
import { SearchFilterBlock } from 'ui-base/searchFilterBlock/SearchFilterBlock'

export const ApplicationAvailabilityFilter = () => {
  const { t } = useTranslation(['applications'])
  const { appFilters, setAppFilters } = useCatalogueFilters()

  const handleChange = useCallback(
    (isChecked: boolean) => {
      setAppFilters({ isAvailable: isChecked })
    },
    [setAppFilters],
  )

  return (
    <SearchFilterBlock filterTitle={t('applications|filters.availability')} data-testid="availability">
      <Flex direction="column" gap={6}>
        <WppToggle
          data-testid="availability-toggle"
          required
          labelConfig={{
            text: t('applications|filters.activated_on_my_workspace'),
          }}
          checked={!!appFilters.isAvailable}
          onWppChange={({ detail: { checked } }) => handleChange(checked)}
        />
      </Flex>
    </SearchFilterBlock>
  )
}
