import { Layout } from 'layout/Layout'
import { CatalogueFiltersProvider } from 'pages/catalogue/main/CatalogueFiltersContext'
import { InternalApiErrorProvider } from 'providers/internalApiError/InternalApiErrorProvider'

// These providers should be wrapped with Route to be able to use router's API
export const AppRoutesProviders = () => (
  <InternalApiErrorProvider>
    <CatalogueFiltersProvider>
      <Layout />
    </CatalogueFiltersProvider>
  </InternalApiErrorProvider>
)
