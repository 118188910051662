import styled from '@emotion/styled'

import { OS_HEADER_HEIGHT } from 'hooks/useOnScreen'
import { ApplicationCheckboxFilter } from 'pages/applications/list/components/ApplicationCheckboxFilter'

export const FiltersWrapper = styled('div')`
  top: ${OS_HEADER_HEIGHT + 30}px;
  height: 100%;
  overflow-x: hidden;
`

export const CategoriesCheckboxFilter = styled(ApplicationCheckboxFilter)`
  .scrollableFilters {
    overflow-y: hidden;
  }
`
