export enum FEATURE_TYPES {
  WPP_EMAIL = 'WPP_EMAIL',
}

export const featureFlags: Record<FEATURE_TYPES, string> = {
  WPP_EMAIL: 'WPP_TEMPLATE_EMAIL',
}

const featureFlagsConfig = Object.keys(FEATURE_TYPES).reduce((acc, type) => {
  const flags =
    process.env.NODE_ENV !== 'development' ? featureFlags[type as FEATURE_TYPES] : process.env[`REACT_APP_${type}`]
  return { ...acc, [type]: flags }
}, {} as Record<FEATURE_TYPES, string>)

export default featureFlagsConfig
