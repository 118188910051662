import styled from '@emotion/styled'
import { WppGrid, WppTopbar, WppTypography } from '@platform-ui-kit/components-library-react'

export const TopBarWrapper = styled('div')`
  background-color: var(--wpp-white-color);
  width: 100%;
  margin: 8px 0 0;
  min-height: 49px;
  border-bottom: 1px solid var(--wpp-grey-color-300);
`

export const Wrapper = styled('div')`
  max-width: var(--wpp-os-content-max-width, auto);
  margin: 0 auto;
`

export const Column = styled(WppGrid)`
  padding-top: 0;
  padding-bottom: 0;
`

export const Toolbar = styled(WppTopbar)`
  &::part(body) {
    padding-left: 0;
    padding-right: 0;
  }

  &::part(divider) {
    display: none;
  }
`

export const Title = styled(WppTypography)`
  margin-right: 32px;
  user-select: none;
`
