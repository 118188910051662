import { useMatch } from 'react-router-dom'

import { ApplicationsFilters } from 'pages/applications/list/components/applicationsFilters/ApplicationsFilters'
import { CatalogueRoutes } from 'pages/catalogue/main/CatalogueMainPage'

export const CatalogueFilters = () => {
  const isApplicationPage = useMatch(CatalogueRoutes.APPLICATIONS)
  if (isApplicationPage) {
    return <ApplicationsFilters />
  }
  return null // TODO add blueprints filters
}
