import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const SvgOsIsNotAvailable = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="188" height="167" viewBox="0 0 188 167" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_f_347_15543)">
      <ellipse cx="93.8737" cy="131.818" rx="71.8332" ry="12.9414" className="fillPrimary300" />
    </g>
    <path
      d="M99 148.491C137.66 148.491 169 117.151 169 78.4911C169 39.8312 137.66 8.49115 99 8.49115C60.3401 8.49115 29 39.8312 29 78.4911C29 117.151 60.3401 148.491 99 148.491Z"
      className="fillPrimary100"
    />
    <g filter="url(#filter1_f_347_15543)">
      <ellipse cx="97" cy="120.5" rx="59" ry="11.5" className="fillPrimary300" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146 28.2857C147.262 28.2857 148.286 27.2624 148.286 26C148.286 24.7376 147.262 23.7143 146 23.7143C144.738 23.7143 143.714 24.7376 143.714 26C143.714 27.2624 144.738 28.2857 146 28.2857ZM146 30C148.209 30 150 28.2091 150 26C150 23.7909 148.209 22 146 22C143.791 22 142 23.7909 142 26C142 28.2091 143.791 30 146 30Z"
      className="fillPrimary300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126 146.286C127.262 146.286 128.286 145.262 128.286 144C128.286 142.738 127.262 141.714 126 141.714C124.738 141.714 123.714 142.738 123.714 144C123.714 145.262 124.738 146.286 126 146.286ZM126 148C128.209 148 130 146.209 130 144C130 141.791 128.209 140 126 140C123.791 140 122 141.791 122 144C122 146.209 123.791 148 126 148Z"
      className="fillPrimary300"
    />
    <path
      d="M20.2985 107.031C22.0586 106.744 23.4816 108.525 22.8456 110.219L21.9838 112.515C21.3544 114.192 19.1761 114.526 18.0518 113.119L16.5283 111.211C15.4041 109.804 16.1712 107.703 17.9131 107.419L20.2985 107.031Z"
      className="fillPrimary300"
    />
    <path
      d="M164.067 103.833C163.656 102.141 165.209 100.63 166.894 101.08L170.289 101.986C172.014 102.447 172.582 104.605 171.306 105.847L168.737 108.347C167.461 109.589 165.312 108.97 164.892 107.239L164.067 103.833Z"
      className="fillPrimary300"
    />
    <path
      d="M89.0064 7.48074C89.5384 5.50642 92.4616 5.50642 92.9936 7.48074L93.2725 8.51546C93.4656 9.23214 94.0604 9.78506 94.8133 9.94785L95.3937 10.0733C97.5354 10.5364 97.5354 13.4636 95.3937 13.9267L94.8133 14.0522C94.0604 14.2149 93.4656 14.7679 93.2725 15.4845L92.9936 16.5193C92.4616 18.4936 89.5384 18.4936 89.0064 16.5193L88.7275 15.4845C88.5344 14.7679 87.9396 14.2149 87.1867 14.0521L86.6063 13.9267C84.4646 13.4636 84.4646 10.5364 86.6063 10.0733L87.1867 9.94785C87.9396 9.78506 88.5344 9.23214 88.7275 8.51546L89.0064 7.48074Z"
      className="fillPrimary400"
    />
    <path
      d="M54.3402 142.604C54.9166 140.465 58.0834 140.465 58.6598 142.604L58.9619 143.725C59.1711 144.501 59.8154 145.1 60.6311 145.277L61.2598 145.413C63.5801 145.914 63.5801 149.086 61.2598 149.587L60.6311 149.723C59.8154 149.9 59.1711 150.499 58.9619 151.275L58.6598 152.396C58.0834 154.535 54.9166 154.535 54.3402 152.396L54.0381 151.275C53.8289 150.499 53.1846 149.9 52.3689 149.723L51.7402 149.587C49.4199 149.086 49.4199 145.914 51.7402 145.413L52.3689 145.277C53.1846 145.1 53.8289 144.501 54.0381 143.725L54.3402 142.604Z"
      className="fillPrimary400"
    />
    <path
      d="M74.7871 70.0105C75.5863 64.0245 77.8838 58.3391 81.4653 53.4846C85.0469 48.6302 89.7956 44.7649 95.2687 42.2494C100.742 39.7338 106.761 38.65 112.764 39.0989C118.768 39.5479 124.56 41.515 129.601 44.8168L143.335 40.1329C143.749 39.996 144.194 39.9809 144.616 40.0895C145.039 40.1981 145.421 40.4258 145.719 40.7457C146.017 41.0656 146.217 41.4644 146.296 41.8948C146.375 42.3252 146.329 42.7692 146.164 43.1744L140.656 56.3542C143.586 61.2498 145.303 66.7781 145.666 72.4763C146.028 78.1746 145.025 83.877 142.739 89.1063C140.454 94.3357 136.953 98.9397 132.53 102.533C128.107 106.126 122.89 108.605 117.317 109.76C111.743 110.916 105.974 110.715 100.494 109.175C95.0131 107.635 89.9801 104.8 85.8157 100.908C81.6514 97.0158 78.477 92.1795 76.5582 86.804C74.6394 81.4284 74.0321 75.6699 74.7871 70.0105Z"
      className="fillPrimary300"
    />
    <path
      d="M51.3149 85.9895C52.1141 91.9755 54.4117 97.6609 57.9932 102.515C61.5747 107.37 66.3235 111.235 71.7965 113.751C77.2696 116.266 83.2886 117.35 89.292 116.901C95.2955 116.452 101.088 114.485 106.129 111.183L119.862 115.867C120.277 116.004 120.721 116.019 121.144 115.911C121.567 115.802 121.949 115.574 122.247 115.254C122.545 114.934 122.745 114.536 122.823 114.105C122.902 113.675 122.857 113.231 122.692 112.826L117.184 99.6458C120.114 94.7502 121.831 89.2219 122.194 83.5237C122.556 77.8254 121.553 72.123 119.267 66.8937C116.982 61.6643 113.481 57.0603 109.058 53.4669C104.635 49.8735 99.4182 47.3953 93.8444 46.2397C88.2707 45.0842 82.5021 45.2849 77.0215 46.825C71.541 48.3651 66.5079 51.1999 62.3436 55.0921C58.1792 58.9842 55.0048 63.8205 53.086 69.196C51.1672 74.5716 50.5599 80.3301 51.3149 85.9895Z"
      className="fillPrimary400"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M80.1807 91.7222C80.1807 87.1855 83.5061 83.8671 87.1836 83.8671C90.8611 83.8671 94.1864 87.1855 94.1864 91.7222H98.9296C98.9296 84.9628 93.8607 79.1239 87.1836 79.1239C80.5064 79.1239 75.4375 84.9628 75.4375 91.7222H80.1807Z"
      className="fillWhite"
    />
    <path
      d="M74.4003 78.9389C76.7536 78.9389 78.6614 77.0311 78.6614 74.6778C78.6614 72.3245 76.7536 70.4167 74.4003 70.4167C72.0469 70.4167 70.1392 72.3245 70.1392 74.6778C70.1392 77.0311 72.0469 78.9389 74.4003 78.9389Z"
      className="fillWhite"
    />
    <path
      d="M99.9667 78.9388C102.32 78.9388 104.228 77.0311 104.228 74.6777C104.228 72.3244 102.32 70.4166 99.9667 70.4166C97.6133 70.4166 95.7056 72.3244 95.7056 74.6777C95.7056 77.0311 97.6133 78.9388 99.9667 78.9388Z"
      className="fillWhite"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.8778 110.095C37.7871 108.232 35.6762 105.622 34.5645 102.187C34.4332 101.782 33.9963 101.557 33.5888 101.688C33.1822 101.816 32.9579 102.25 33.0892 102.656C34.3206 106.458 36.6553 109.351 40.0772 111.414C40.4431 111.635 40.9208 111.517 41.1412 111.154C41.3626 110.789 41.2445 110.314 40.8778 110.095Z"
      className="fillPrimary500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4229 142.361C27.9532 143.121 28.5525 143.836 29.2201 144.486C29.5172 144.776 30.1679 145.319 30.7905 145.542C31.4531 145.782 32.0867 145.705 32.5452 145.195C33.1536 144.518 33.2784 143.881 33.1651 143.334C33.0565 142.808 32.6968 142.323 32.1459 141.93C31.0927 141.181 29.2875 140.771 28.2062 140.729C27.7302 139.942 27.3297 139.111 27.0063 138.249C24.598 131.833 28.1208 124.3 35.4882 119.742C38.0674 118.147 42.5661 118.247 47.2675 118.927C54.5879 119.985 62.3908 122.569 64.6964 123.253C65.2661 123.421 65.6208 123.458 65.6968 123.446C66.0685 123.395 66.2213 123.171 66.3002 123.007C66.4118 122.769 66.417 122.556 66.3759 122.373C66.3187 122.125 66.1637 121.892 65.8733 121.725C65.7125 121.635 65.4753 121.559 65.2175 121.497C65.0382 121.455 64.8345 121.439 64.7166 121.37C63.9627 120.92 63.2406 120.428 62.5112 119.94C60.0576 118.308 57.8451 116.452 55.6704 114.466C48.7601 108.157 41.3593 101.864 37.0696 93.3932C36.877 93.0133 36.4108 92.8573 36.0286 93.0491C35.6471 93.2379 35.4936 93.7012 35.6857 94.0842C40.0597 102.724 47.5771 109.166 54.624 115.601C56.474 117.29 58.3516 118.888 60.3741 120.338C56.4182 119.193 50.9018 117.76 45.8358 117.187C41.3305 116.677 37.1743 116.882 34.667 118.432C26.5962 123.425 22.9156 131.759 25.5546 138.785C25.8373 139.537 26.1728 140.27 26.5614 140.974C20.127 142.57 16.1171 148.586 17.2453 155.146C17.3171 155.567 17.7183 155.85 18.1396 155.78C18.5618 155.707 18.8452 155.31 18.7734 154.889C17.7568 148.978 21.5054 143.578 27.4229 142.361ZM29.4167 142.429C29.6953 142.762 29.9913 143.08 30.3032 143.386C30.4711 143.549 30.7995 143.841 31.1491 144.02C31.2312 144.061 31.3665 144.1 31.4295 144.117C31.5861 143.938 31.6781 143.79 31.6467 143.642C31.6088 143.456 31.4392 143.322 31.2463 143.184C30.7626 142.838 30.0673 142.583 29.4167 142.429Z"
      className="fillPrimary500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.332 40.2942C115.248 37.5816 119.084 33.9314 121.936 30.1086C122.184 29.7746 122.115 29.3017 121.78 29.0542C121.446 28.8067 120.972 28.875 120.723 29.2089C117.966 32.905 114.256 36.4354 110.469 39.0564C110.127 39.2942 110.042 39.7642 110.28 40.1051C110.519 40.4461 110.99 40.532 111.332 40.2942ZM108.732 30.4443L108.732 30.4443L108.732 30.4443C108.78 30.1417 108.828 29.8376 108.878 29.5314C108.945 29.1199 108.666 28.7329 108.254 28.6651C107.843 28.5972 107.454 28.877 107.387 29.2884C107.333 29.613 107.283 29.9354 107.234 30.256C106.977 31.9085 106.728 33.5124 106.097 35.1166C105.944 35.504 106.136 35.9425 106.524 36.0934C106.912 36.2466 107.351 36.0547 107.503 35.6673C108.182 33.944 108.452 32.2233 108.732 30.4443ZM121.353 43.5068C118.281 44.3845 114.871 44.8394 111.75 43.8866C111.352 43.7647 111.127 43.342 111.249 42.9454C111.37 42.5488 111.793 42.3243 112.191 42.4461C115.034 43.3135 118.138 42.8581 120.936 42.0577C121.337 41.9442 121.756 42.1751 121.871 42.5756C121.986 42.9735 121.754 43.3908 121.353 43.5068Z"
      className="fillPrimary500"
    />
    <defs>
      <filter
        id="filter0_f_347_15543"
        x="0.267532"
        y="97.1031"
        width="187.212"
        height="69.4289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_347_15543" />
      </filter>
      <filter
        id="filter1_f_347_15543"
        x="16.227"
        y="87.227"
        width="161.546"
        height="66.546"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_347_15543" />
      </filter>
    </defs>
  </S.CustomSvg>
)
