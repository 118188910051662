import { WppSpinner } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useApplicationsQuickSearchApi } from 'api/applications/queries/useApplicationsQuickSearchApi'
import { PageSizes } from 'constants/pageSizes'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { DEBOUNCE_SEARCH_MS, SEARCH_QUERY_MIN_LENGTH } from 'pages/applications/list/components/ApplicationsSearch'
import { boldedText, getNotEmptyFilters } from 'pages/applications/list/utils'
import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { ApplicationDTO } from 'types/applications/application'
import { RoutesManager } from 'utils/routesManager'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

interface Props {
  searchField: string
  clearSearchInput: () => void
}

export const QuickSearchResults = ({ searchField, clearSearchInput }: Props) => {
  const { t } = useTranslation(['applications'])
  const navigate = useNavigate()
  const {
    osContext: {
      tenant: { azId: currentUserTenantId },
    },
  } = useOs()
  const { appFilters } = useCatalogueFilters()
  const notEmptyQueryFilters = getNotEmptyFilters(appFilters)
  const debouncedSearchText = useDebouncedValue(searchField, DEBOUNCE_SEARCH_MS)

  const { data, isLoading } = useApplicationsQuickSearchApi({
    params: {
      size: PageSizes.PAGINATED_APPLICATIONS_QUICK_SEARCH_SIZE,
      currentUserTenantId,
      ...notEmptyQueryFilters,
      ...{ search: debouncedSearchText },
    },
    enabled: debouncedSearchText.length >= SEARCH_QUERY_MIN_LENGTH,
  })

  const handleMouseDown = (val: ApplicationDTO) => {
    navigate(RoutesManager.applications.view.getURL({ id: val.id }))
    clearSearchInput()
  }

  if (isLoading) {
    return (
      <S.Hint className="hint" tag="p" type="s-body">
        <WppSpinner size="s" data-testid="quick-search-loading" />
      </S.Hint>
    )
  }

  if (data.length === 0) {
    return (
      <S.Hint className="hint" tag="p" type="s-body">
        {t('applications|filters.filter_empty_search')}
      </S.Hint>
    )
  }

  return (
    <S.QuickResults className="hint" data-testid="quick-search-results">
      {data.slice(0, PageSizes.PAGINATED_APPLICATIONS_QUICK_SEARCH_SIZE - 1).map((val, key) => (
        <S.ApplicationSearchRow key={key} onMouseDown={() => handleMouseDown(val)}>
          <S.ApplicationName tag="p" type="s-body">
            <S.CanHaveMatch dangerouslySetInnerHTML={{ __html: boldedText(val.name, searchField) }} />
          </S.ApplicationName>
          <S.ApplicationDescription tag="p" type="s-body">
            <S.CanHaveMatch dangerouslySetInnerHTML={{ __html: boldedText(val.shortDescription, searchField) }} />
          </S.ApplicationDescription>
        </S.ApplicationSearchRow>
      ))}
    </S.QuickResults>
  )
}
