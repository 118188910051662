import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { ErrorPageContent } from 'ui-base/errorPage/components/ErrorPageContent'
import { SvgSomethingWentWrong } from 'ui-base/svgIcons/errorState/assets/SvgSomethingWentWrong'
import { BaseErrorBoundary } from 'ui-shared/errorBoundaries/common/BaseErrorBoundary'

import * as S from 'ui-shared/errorBoundaries/global/GlobalErrorBoundary.styled'

export const GlobalErrorBoundary = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation(['errors', 'common'])

  return (
    <BaseErrorBoundary
      fallback={
        <S.PageContainer direction="column" align="center" justify="center">
          <ErrorPageContent
            title={t('errors|global_error')}
            subTitle={t('errors|global_error_subtitle')}
            image={<SvgSomethingWentWrong />}
            handleClick={() => window.location.reload()}
            buttonText={t('errors|reload_page')}
          />
        </S.PageContainer>
      }
    >
      {children}
    </BaseErrorBoundary>
  )
}
