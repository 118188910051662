import styled from '@emotion/styled'

import { Flex } from 'ui-base/flex/Flex'

export type LogoProps = {
  size?: 'xs' | 's' | 'm' | 'l'
  isCircle?: boolean
}

export const LogoSize = {
  xs: '32px',
  s: '40px',
  m: '64px',
  l: '120px',
}

export const Logo = styled('img')<LogoProps>`
  display: block;
  border-radius: ${props => (props.isCircle ? '50%' : '8px')};
  width: ${props => (props.size ? `${LogoSize[props.size]}` : LogoSize['l'])};
  height: ${props => (props.size ? `${LogoSize[props.size]}` : LogoSize['l'])};
`

export const LogoPlaceholderWrapper = styled(Flex, {
  shouldForwardProp: props => props !== 'size' && props !== 'isCircle',
})<LogoProps>`
  flex-shrink: 0;
  opacity: 1;
  border-radius: ${props => (props.isCircle ? '50%' : '8px')};
  width: ${props => (props.size ? `${LogoSize[props.size]}` : LogoSize['l'])};
  height: ${props => (props.size ? `${LogoSize[props.size]}` : LogoSize['l'])};
  background-color: var(--wpp-grey-color-200);
`

export const LogoWrapper = styled('div')<LogoProps>`
  width: ${props => (props.size ? `${LogoSize[props.size]}` : LogoSize['l'])};
  height: ${props => (props.size ? `${LogoSize[props.size]}` : LogoSize['l'])};
`
