import { TreeType, TreeChangeEventDetail } from '@platform-ui-kit/components-library'

import { useInfiniteApplicationsApi } from 'api/applications/queries/useInfiniteApplicationsApi'
import { FiltersAppType, FiltersType } from 'pages/catalogue/main/CatalogueFiltersContext'
import { ApplicationCategory } from 'types/applications/enums'
import { MarketDTO } from 'types/applications/market'

export const applicationsTypesByRenderingOrder = [
  ApplicationCategory.MEDIA,
  ApplicationCategory.PRODUCTION,
  ApplicationCategory.BRAND,
  ApplicationCategory.COMMERCE,
  ApplicationCategory.CREATIVE,
  ApplicationCategory.EXPERIENCE,
  ApplicationCategory.PR,
  ApplicationCategory.DATA,
  ApplicationCategory.OTHER_APPS,
]

export const AllCategoriesRequest = 'ALL'
export type AppCategoryRequests = keyof typeof ApplicationCategory | typeof AllCategoriesRequest

export type ApplicationsQuery = ReturnType<typeof useInfiniteApplicationsApi>
export type AppsQueriesMap = Record<AppCategoryRequests, ApplicationsQuery>

export const getResultsNumber = (visibleQueries: ApplicationsQuery[]) =>
  visibleQueries
    .map(({ response }) => response?.pages?.[response.pages.length - 1].data.totalElements || 0)
    .reduce((acc, queryTotalElements) => acc + queryTotalElements)

export const getNotEmptyFilters = (filters: Partial<FiltersAppType>): Partial<FiltersAppType> =>
  Object.fromEntries(
    Object.entries(filters).filter(([key, value]) => {
      if (typeof value === 'boolean') {
        return value
      }
      return !!value?.length && key !== 'searchFieldValue'
    }),
  )

export const regionMarketsArrToTree = (markets: MarketDTO[]): TreeType[] => {
  const regionMarketsObj = markets.reduce((all, current) => {
    if (!current || !current.region) return all
    if (!all[current.region]) {
      all[current.region] = []
    }
    all[current.region].push(current)
    return all
  }, {} as Record<string, MarketDTO[]>)

  const sortedRegionMarketsArr = Object.entries(regionMarketsObj).sort((a, b) => a[0].localeCompare(b[0]))

  return sortedRegionMarketsArr.map(([region, markets]) => ({
    title: region,
    id: `REGION_${region}`,
    value: region,
    region: region,
    children: markets
      .map(item => ({
        title: item.countryTitle,
        id: `MARKET_${item.countryCode}`,
        region: region,
        value: item.countryCode,
      }))
      .sort((a, b) => a.title.localeCompare(b.title)),
  }))
}

export const selectedItemsToData = (
  selectedItems: TreeChangeEventDetail['selectedItems'],
): Pick<FiltersAppType, 'regions' | 'markets'> => {
  const state = { regions: [], markets: [] }
  if (!selectedItems) return state
  const selectedValues = selectedItems.reduce(
    (all, currentItem) => {
      if (!currentItem?.selected) return all
      const isRegion = !!currentItem?.children?.length // region has children
      if (!isRegion) {
        all.regions.add(currentItem.region)
        all.markets.add(currentItem?.value)
      }
      return all
    },
    {
      regions: new Set<string>(),
      markets: new Set<string>(),
    },
  )
  return {
    ...state,
    markets: [...selectedValues.markets],
    regions: [...selectedValues.regions],
  }
}

export const removeMarketFromTreeState =
  (filtersState: FiltersType) =>
  (marketToRemove: string): FiltersType => {
    const updatedMarkets = filtersState.applicationFilters.markets.filter(item => item !== marketToRemove)
    const updatedRegions: string[] = []
    const updatedTreeState: TreeType[] = filtersState.regionMarketsTreeState.map(regionItem => {
      const updatedRegionMarkets = regionItem.children?.map(marketItem => ({
        ...marketItem,
        selected: updatedMarkets.includes(marketItem.value),
      }))
      const indeterminate = updatedRegionMarkets?.some(item => item.selected)
      if (indeterminate) updatedRegions.push(regionItem.value)
      return {
        ...regionItem,
        children: updatedRegionMarkets,
        selected: updatedRegionMarkets?.every(item => item.selected),
        indeterminate,
      }
    })

    return {
      ...filtersState,
      applicationFilters: {
        ...filtersState.applicationFilters,
        markets: updatedMarkets,
        regions: updatedRegions,
      },
      regionMarketsTreeState: updatedTreeState,
    }
  }

export const toggledTreeState = (treeState: TreeType[], selected: boolean): TreeType[] => {
  return treeState.map(region => ({
    ...region,
    open: false,
    selected: selected,
    children: (region.children || []).map(market => ({
      ...market,
      open: false,
      selected: selected,
    })),
  }))
}

export const boldedText = (originalText: string, search: string) => {
  const text = originalText.toUpperCase()
  const query = search.toUpperCase()
  const startingPosition = text.indexOf(query)

  if (!query || startingPosition === -1) {
    return originalText
  }

  const beforeMatchedSubstr = originalText.substring(0, startingPosition)
  const matchedSubstr = originalText.substring(startingPosition, startingPosition + query.length)
  const restSubstr = originalText.substring(startingPosition + query.length, text.length)

  return `${beforeMatchedSubstr}<b>${matchedSubstr}</b>${restSubstr}`
}
