import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes } from 'react'

import * as S from 'ui-base/errorPage/ErrorPage.styled'

interface Props extends HTMLAttributes<HTMLDivElement> {
  image: JSX.Element
  title: string
  subTitle?: string
  handleClick: () => void
  buttonText: string
}

export const ErrorPageContent = ({ image, title, subTitle, handleClick, buttonText, ...rest }: Props) => (
  <S.PageContent direction="column" align="center" gap={16} data-testid="error-page" {...rest}>
    {image}
    <WppTypography type="5xl-display" data-testid="error-title">
      {title}
    </WppTypography>
    {!!subTitle && (
      <WppTypography type="m-body" data-testid="error-subtitle">
        {subTitle}
      </WppTypography>
    )}
    <WppButton onClick={handleClick} data-testid="error-button">
      {buttonText}
    </WppButton>
  </S.PageContent>
)
