import { useMemo, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ApiErrorTypes } from 'constants/apiErrors'
import { ErrorPageContent } from 'ui-base/errorPage/components/ErrorPageContent'
import { SvgForbidden } from 'ui-base/svgIcons/errorState/assets/SvgForbidden'
import { SvgNotFound } from 'ui-base/svgIcons/errorState/assets/SvgNotFound'
import { SvgOsIsNotAvailable } from 'ui-base/svgIcons/errorState/assets/SvgOsIsNotAvailable'
import { SvgSomethingWentWrong } from 'ui-base/svgIcons/errorState/assets/SvgSomethingWentWrong'
import { RoutesManager } from 'utils/routesManager'

interface Props extends HTMLAttributes<HTMLDivElement> {
  errorType: ApiErrorTypes
}

export const ErrorPage = ({ errorType, ...rest }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation('errors')

  const pageConfig = useMemo(
    () =>
      ({
        [ApiErrorTypes.NOT_FOUND]: {
          image: <SvgNotFound data-testid="not-found-image" />,
          title: t('not_found'),
          buttonText: t('go_to_application_catalogue'),
          buttonAction: () => navigate(RoutesManager.applications.root.getURL()),
        },
        [ApiErrorTypes.ACCESS_DENIED]: {
          image: <SvgForbidden />,
          title: t('access_denied.page'),
          buttonText: t('go_to_application_catalogue'),
          buttonAction: () => navigate(RoutesManager.applications.root.getURL()),
        },
        [ApiErrorTypes.SERVER_ERROR]: {
          image: <SvgSomethingWentWrong />,
          title: t('server_error'),
          buttonText: t('reload_page'),
          buttonAction: () => navigate(0),
        },
        [ApiErrorTypes.OTHER_ERROR]: {
          image: <SvgOsIsNotAvailable />,
          title: t('global_error'),
          subTitle: t('global_error_subtitle'),
          buttonText: t('reload_page'),
          buttonAction: () => navigate(0),
        },
      }[errorType]),
    [errorType, navigate, t],
  )

  return (
    <ErrorPageContent
      image={pageConfig.image}
      title={pageConfig.title}
      subTitle={pageConfig.subTitle}
      buttonText={pageConfig.buttonText}
      handleClick={pageConfig.buttonAction}
      {...rest}
    />
  )
}
