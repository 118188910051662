import { WppAccordion, WppIconCross, WppIconSearch, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { ComponentProps, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import * as S from 'ui-base/searchFilterBlock/SearchFilterBlock.styled'

interface Props extends Omit<ComponentProps<typeof WppAccordion>, 'onChange'> {
  filterTitle: string | React.ReactNode
  searchValue?: string
  onSearchChange?: MayBeNull<(term: string) => void>
  nestedFilters?: boolean
}

export const SearchFilterBlock = ({
  filterTitle,
  onSearchChange,
  searchValue,
  nestedFilters,
  children,
  ...rest
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation(['applications', 'common'])

  return (
    <S.FiltersAccordion size="m" expandedByDefault {...rest}>
      <WppTypography type="s-strong" slot="header">
        {filterTitle}
      </WppTypography>
      <S.FilterBody direction="column" gap={12}>
        {onSearchChange && (
          <S.SearchInput
            size="s"
            placeholder={t('common|search')}
            onWppChange={e => onSearchChange(e.target.value)}
            value={searchValue}
            data-testid="filter-search"
          >
            <WppIconSearch slot="icon-start" />
            {searchValue && <WppIconCross slot="icon-end" onClick={() => onSearchChange('')} />}
          </S.SearchInput>
        )}
        <S.FilterControlWrapper hasLeftPadding={!nestedFilters}>{children}</S.FilterControlWrapper>
      </S.FilterBody>
    </S.FiltersAccordion>
  )
}
