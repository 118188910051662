import { Component, PropsWithChildren } from 'react'

/**
 * This is a custom error boundary to catch Webpack import errors: ChunkLoadError.
 * They occur when a new release is deployed and the user's browser
 * tries to load an old chunk that was renamed.
 */
export class ImportErrorBoundary extends Component<PropsWithChildren> {
  componentDidCatch(error: Error) {
    // NOTE: Webpack adds inline scripts that generate such errors, so we have to rely on error name
    // See https://github.com/webpack/webpack/blob/v4.46.0/lib/web/JsonpMainTemplatePlugin.js#L178-L207
    if (error && error.name === 'ChunkLoadError') {
      console.error('Caught application chunk loading error in ImportErrorBoundary. Reloading page.')

      window.location.reload()
    } else {
      // Rethrow error if it's not a Webpack import error, it should be caught by a global error boundary
      throw error
    }
  }

  render() {
    return <>{this.props.children}</>
  }
}
