import { ReactNode, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'ui-base/tooltip/Tooltip'

import * as S from 'ui-base/checkboxGroup/CheckboxGroup.styled'

export type BaseOption = Record<string, any>

export interface Props<O, V> extends Omit<HTMLAttributes<HTMLUListElement>, 'onChange'> {
  name?: string
  options: O[]
  getOptionLabel?: (option: O) => ReactNode
  getOptionValue?: (option: O) => V
  value: V[]
  onChange: (newValue: V[], option: O) => any
  showTooltip?: boolean
  marginTop?: number
  labelWidth?: number
}

function defaultGetOptionLabel(option: BaseOption) {
  return option.label
}

function defaultGetOptionValue(option: BaseOption) {
  return option.value
}

const LABEL_WIDTH = 140
export const CheckboxItems = <O extends BaseOption, V extends string | number>({
  name = '',
  value,
  onChange,
  options,
  getOptionLabel = defaultGetOptionLabel,
  getOptionValue = defaultGetOptionValue,
  showTooltip,
  marginTop,
  labelWidth = LABEL_WIDTH,
}: Props<O, V>) => {
  const { t } = useTranslation()

  return (
    <>
      {options.map(option => {
        const optionValue = getOptionValue(option)
        const optionLabel = getOptionLabel(option)
        const isChecked = value.includes(optionValue)

        return (
          <S.ListItem key={optionValue} marginTop={marginTop} data-testid={`item-${optionLabel}`}>
            {/* TODO remove "required" prop when CL will allow hiding "Optional" label */}
            <Tooltip
              getInnerElement={el =>
                el?.shadowRoot
                  ?.querySelector('.wpp-internal-label')
                  ?.shadowRoot?.querySelector('.wpp-typography[part="text"]')
                  ?.shadowRoot?.querySelector('[part="typography"]')
              }
              showOnOverflow
              show={!!showTooltip}
              text={optionLabel as string}
            >
              <S.Checkbox
                labelWidth={labelWidth}
                required
                data-testid={`item-${optionLabel}-checkbox`}
                name={`${name}.${optionValue}`}
                labelConfig={{ text: `${optionLabel}`, locales: { optional: t('optional') } }}
                checked={isChecked}
                onWppChange={() => {
                  const newValue = isChecked ? value.filter(val => val !== optionValue) : [...value, optionValue]

                  onChange(newValue, option)
                }}
                disabled={option.disabled}
              />
            </Tooltip>
          </S.ListItem>
        )
      })}
    </>
  )
}
