import { useTranslation } from 'react-i18next'

import { ApplicationSelectableCardsFilter } from 'pages/applications/list/components/ApplicationSelectableCardsFilter'
import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { CommercialModel } from 'types/applications/enums'
import { CommercialModelLabel } from 'types/applications/labels'

const commercialModelsOptions = [
  {
    value: CommercialModel.CONTRACT,
    label: CommercialModelLabel[CommercialModel.CONTRACT],
  },
  {
    value: CommercialModel.FIXED_PRICE,
    label: CommercialModelLabel[CommercialModel.FIXED_PRICE],
  },
  {
    value: CommercialModel.FREE,
    label: CommercialModelLabel[CommercialModel.FREE],
  },
  {
    value: CommercialModel.PAY_AS_YOU_GO,
    label: CommercialModelLabel[CommercialModel.PAY_AS_YOU_GO],
  },
]

export const ApplicationCommercialModel = () => {
  const { t } = useTranslation(['applications'])
  const { appFilters, setAppFilters } = useCatalogueFilters()

  return (
    <ApplicationSelectableCardsFilter
      data-testid="commercial-model"
      filterTitle={t('applications|filters.commercial_model')}
      options={commercialModelsOptions.map(item => ({
        ...item,
        label: t(`applications|${item.label}`),
      }))}
      value={appFilters.commercialModels || []}
      onChange={value => setAppFilters({ commercialModels: value as CommercialModel[] })}
    />
  )
}
