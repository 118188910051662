import { useMemo } from 'react'

import { createUseQuery } from 'api/common/createUseQuery'
import { fetchAllTenantsApi } from 'api/tenants/fetches/fetchAllTenantsApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

const useAllTenantsApi = createUseQuery({
  queryKey: ApiQueryKeys.TENANTS,
  fetcher: fetchAllTenantsApi,
  selector: res => res?.data?.data || [],
})

export const useGetAllTenantsMap = (enabled = true) => {
  const { data: tenants } = useAllTenantsApi({
    params: { itemsPerPage: 1000 },
    enabled,
  })

  return useMemo(() => Object.fromEntries(tenants.map(tenant => [tenant.id, tenant])), [tenants])
}
