import { useState, useCallback } from 'react'

import { useAuth } from 'hooks/useAuth'

const BANNER_STATE_LS_NAME = 'wpp-marketplace-session-banner-state'
export const getBannerStateValue = () => window.localStorage.getItem(BANNER_STATE_LS_NAME)
export const setClosedBannerState = (token: string) => window.localStorage.setItem(BANNER_STATE_LS_NAME, token)

export const useInfoBannerState = () => {
  const { getAccessToken } = useAuth()
  const token = getAccessToken()!
  // if token is the same it means that a banner was closed in the current session
  const [isClosed, setIsClosed] = useState(getBannerStateValue() === token)

  const handleClose = useCallback(() => {
    setClosedBannerState(token)
    setIsClosed(true)
  }, [token])

  return {
    isClosed,
    handleClose,
  }
}
