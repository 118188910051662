import { useEffect, useRef, useState } from 'react'

import { getCssPropertyVal } from 'utils/getCssPropertyVal'

export const OS_HEADER_HEIGHT = getCssPropertyVal({ cssName: '--wpp-os-header-height', fallbackVal: 64 })

export const useOnScreen = (defaultState = false, customRootMargin?: string) => {
  const rootMargin = customRootMargin || `-${OS_HEADER_HEIGHT}px`

  const [isIntersecting, setIntersecting] = useState(defaultState)
  const ref = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      { rootMargin },
    )
    if (ref?.current) {
      observer.observe(ref.current)
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref?.current && observer.unobserve(ref.current)
    }
  }, [rootMargin])

  return { isIntersecting, ref }
}
