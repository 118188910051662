import { WppTypography } from '@platform-ui-kit/components-library-react'
import { forwardRef, ComponentProps } from 'react'

import * as S from 'ui-base/truncate/Truncate.styled'

type Props = ComponentProps<typeof WppTypography> & {
  lines?: number
}

export const Truncate = forwardRef<HTMLWppTypographyElement, Props>(({ lines = 0, style, ...rest }, ref) => {
  if (lines) {
    return (
      <S.Multiline
        {...rest}
        ref={ref}
        {...((!!style || !!lines) && {
          style: { ...style, '--lines': lines },
        })}
      />
    )
  }

  return <S.SingleLine {...rest} ref={ref} style={style} />
})
