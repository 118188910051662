import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { DataBannerSvg } from 'pages/applications/list/components/DataBannerSvg'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

const DATA_CATALOGUE_URL = 'https://wpp.app.data.world/wppopendatacatalog'

export const DataBanner = () => {
  const { t } = useTranslation(['applications'])

  return (
    <S.DataBannerWrapper item all={12}>
      <S.DataBannerCard data-testid="data-banner">
        <Flex justify="between">
          <S.DataBannerInfo direction="column" gap={20}>
            <WppTypography tag="p" type="2xl-heading" data-testid="data-banner-title">
              {t('applications|data_banner.title')}
            </WppTypography>
            <S.DataBannerDescription tag="p" type="s-body" data-testid="data-banner-description">
              {t('applications|data_banner.description')}
            </S.DataBannerDescription>
            <WppButton onClick={() => window.open(DATA_CATALOGUE_URL)} data-testid="data-banner-button">
              {t('applications|data_banner.button')}
            </WppButton>
          </S.DataBannerInfo>
          <Flex>
            <DataBannerSvg />
          </Flex>
        </Flex>
      </S.DataBannerCard>
    </S.DataBannerWrapper>
  )
}
