import { useOs } from '@wpp-open/react'
import { PropsWithChildren } from 'react'

import { UserDataContext } from 'providers/userData/UserDataContext'

export const UserDataProvider = ({ children }: PropsWithChildren) => {
  const { osContext, osApi } = useOs()
  const { tenant, userDetails } = osContext
  const { getAccessToken } = osApi

  return (
    <UserDataContext.Provider
      value={{
        isAuthenticated: !!getAccessToken(),
        getAccessToken,
        userDetails,
        tenant,
      }}
    >
      {children}
    </UserDataContext.Provider>
  )
}
