import styled from '@emotion/styled'

import { Flex } from 'ui-base/flex/Flex'
import { EmptyFolderSvg } from 'ui-base/svgIcons/emptyState/assets/EmptyFolderSvg'

export const Root = styled(Flex)<{ margin?: string }>`
  margin: ${({ margin }) => (margin ? margin : 'auto')};
`

export const EmptyFolder = styled(EmptyFolderSvg)`
  width: 100px;
  height: 100px;
`
