import { WppTypography } from '@platform-ui-kit/components-library-react'
import { PropsWithChildren } from 'react'

import * as S from 'ui-base/emptyState/EmptyState.styled'

type Props = {
  message?: {
    title?: string
    subtitle?: string
  }
  showImage?: boolean
  margin?: string
} & JSX.IntrinsicElements['div']

export const EmptyState = ({ message, showImage, margin, children, ...rest }: PropsWithChildren<Props>) => (
  <S.Root align="center" direction="column" gap={8} margin={margin} {...rest}>
    {showImage && <S.EmptyFolder data-testid="empty-folder" />}
    {message?.title && (
      <WppTypography type="l-midi" data-testid="empty-results-title">
        {message.title}
      </WppTypography>
    )}
    {message?.subtitle && (
      <WppTypography type="s-body" data-testid="empty-results-subtitle">
        {message.subtitle}
      </WppTypography>
    )}
    {children}
  </S.Root>
)
