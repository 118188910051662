interface Props {
  cssName: string
  fallbackVal: any
}

export const getCssPropertyVal = ({ cssName, fallbackVal }: Props) => {
  const value = window.getComputedStyle(document.documentElement)?.getPropertyValue(cssName)
  const parsedValue = parseFloat(value?.match(/\d+/)?.[0] || '')
  return parsedValue || fallbackVal
}
