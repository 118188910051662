import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { InternalApiErrorContext } from 'providers/internalApiError/InternalApiErrorContext'

export const InternalApiErrorProvider = ({ children }: PropsWithChildren) => {
  const [error, setError] = useState<any>(null)
  const { pathname } = useLocation()

  // We need to clean the error if user wants to switch from broken page.
  useEffect(() => {
    setError(null)
  }, [pathname])

  const addError = useCallback((error: any) => setError(error), [])

  return (
    <InternalApiErrorContext.Provider
      value={{
        error,
        addError,
      }}
    >
      {children}
    </InternalApiErrorContext.Provider>
  )
}
