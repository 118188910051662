import styled from '@emotion/styled'
import { WppAccordion, WppInput } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const FiltersAccordion = styled(WppAccordion)`
  &::part(content) {
    margin: 6px 0 15px 0;
  }
`

export const FilterBody = styled(Flex)`
  width: 100%;
`

export const FilterControlWrapper = styled('div', { shouldForwardProp: prop => prop !== 'hasLeftPadding' })<{
  hasLeftPadding: boolean
}>`
  padding-left: ${({ hasLeftPadding }) => (hasLeftPadding ? '22px' : '0')};
`

export const SearchInput = styled(WppInput)`
  &::part(input) {
    width: 100%;
  }
`
