import styled from '@emotion/styled'
import { WppActionButton, WppPill, WppPillGroup, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const FiltersTagsWrapper = styled('div')`
  margin-top: 10px;
`

export const ResultsLabel = styled(WppTypography, { shouldForwardProp: props => props !== 'isLoading' })<{
  isLoading: boolean
}>`
  white-space: nowrap;
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
  color: var(--wpp-grey-color-800);
`

export const ExpandButton = styled(WppActionButton, { shouldForwardProp: props => props !== 'shown' })<{
  shown: boolean
}>`
  opacity: ${({ shown }) => (shown ? 1 : 0)};
`

export const SearchKeyword = styled(WppTypography)`
  margin-left: 5px;
`

export const LineOfTagsWrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: opacity 0.3s ease;

  &.shown {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
  }
`

export const HiddenTags = styled(WppPillGroup)`
  height: 0;
  visibility: hidden;
`

export const TagsContainer = styled('div')`
  overflow-y: hidden;
  transition: max-height 0.3s ease;

  &.shown {
    max-height: 100vh;
  }

  &.hidden {
    max-height: 0;
  }
`

export const TagsWrapper = styled(Flex)`
  margin: 18px 0 0;
`

export const TagsGroupTypography = styled(WppTypography)`
  color: var(--wpp-grey-color-800);
`

export const Tag = styled(WppPill)`
  margin-bottom: 4px;

  &::part(icon-start-wrapper) {
    margin: 0 4px 0 0;
  }
`

export const LocalTag = styled(WppPill)`
  &::part(icon-start-wrapper) {
    margin: 0 4px 0 0;
  }
`

export const CategoryTagImage = styled('img')`
  display: flex;
  width: 20px;
  height: 20px;
  object-fit: contain;
`
