import styled from '@emotion/styled'
import { WppTooltip } from '@platform-ui-kit/components-library-react'

export const Tooltip = styled(WppTooltip)`
  max-width: 100%;
  display: inline-block;
  &::part(anchor) {
    max-width: 100%;
  }
`

export const ChildWrap = styled('div')`
  max-width: 100%;
`
