type getURLParams = {
  id: string | number
}

export const RoutesManager = {
  main: {
    root: {
      pattern: '/*',
      getURL: () => '/*',
    },
  },
  applications: {
    root: {
      pattern: '/applications',
      getURL: () => '/applications',
    },
    view: {
      pattern: '/applications/view/:id/*',
      getURL: ({ id }: getURLParams) => `/applications/view/${id}`,
    },
  },
  blueprints: {
    root: {
      pattern: '/blueprints',
      getURL: () => '/blueprints',
    },
    view: {
      pattern: '/blueprints/view/:id',
      getURL: ({ id }: getURLParams) => `/blueprints/view/${id}`,
    },
  },
  collections: {
    root: {
      pattern: '/collections',
      getURL: () => '/collections',
    },
    view: {
      pattern: '/collections/view/:id/*',
      getURL: ({ id }: getURLParams) => `/collections/view/${id}`,
    },
  },
}
