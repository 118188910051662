import { useMemo } from 'react'

import { getNotEmptyFilters } from 'pages/applications/list/utils'
import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'

export const useApplicationSearch = () => {
  const {
    filtersState: { applicationFilters },
  } = useCatalogueFilters()

  const { hasFilters, isSearchApplied, queryFilters } = useMemo(() => {
    const notEmptyQueryFilters = getNotEmptyFilters(applicationFilters)
    const queryFiltersLength = Object.keys(notEmptyQueryFilters).length
    return {
      hasFilters: queryFiltersLength > 0,
      isSearchApplied: !!notEmptyQueryFilters?.search?.length,
      queryFilters: notEmptyQueryFilters,
    }
  }, [applicationFilters])

  return {
    hasFilters,
    queryFilters,
    isSearchApplied,
  }
}
