import { WppActionButton } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { useMatch } from 'react-router-dom'

import { useInfoBannerState } from 'layout/infoBanner/useInfoBannerState'
import { useApplicationSearch } from 'pages/applications/list/useApplicationSearch'
import { CatalogueRoutes } from 'pages/catalogue/main/CatalogueMainPage'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'layout/infoBanner/InfoBanner.styled'

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const InfoBanner = ({ ...rest }: Props) => {
  const { isClosed, handleClose } = useInfoBannerState()
  const { t } = useTranslation(['applications'])
  const matchApplicationPage = useMatch(CatalogueRoutes.APPLICATIONS)
  const isApplicationMainPage = matchApplicationPage?.params?.['*'] === ''
  const { hasFilters: hasApplicationFilters } = useApplicationSearch()

  if (isClosed || !isApplicationMainPage || hasApplicationFilters) {
    return null
  }

  return (
    <S.InfoBanner data-testid="info-message-banner" {...rest}>
      <Flex gap={12}>
        <S.IconInfo />
        <S.BannerText data-testid="banner-text-message" type="s-midi">
          {t('applications|banner_certification_levels')}
        </S.BannerText>
      </Flex>
      <WppActionButton data-testid="banner-close-button" onClick={handleClose}>
        <S.IconCross slot="icon-start" />
      </WppActionButton>
    </S.InfoBanner>
  )
}
