import * as S from 'ui-base/svgIcons/SvgIcons.styled'

export const EmptyFolderSvg = (props: JSX.IntrinsicElements['svg']) => (
  <S.CustomSvg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="11.1001" y="13.6197" width="69.1889" height="68.2081" rx="12" className="fillPrimary300" />
    <path
      d="M1.45963 35.2232C0.941575 28.2641 6.44814 22.3324 13.4265 22.3324H34.4855C38.3316 22.3324 41.718 24.8661 42.8035 28.5558C43.889 32.2455 47.2755 34.7791 51.1215 34.7791H75.658C82.7037 34.7791 88.2333 40.8206 87.6111 47.8388L83.9569 89.0597C83.408 95.2521 78.2206 100 72.0038 100H17.4217C11.1399 100 5.92115 95.1553 5.45481 88.8909L1.45963 35.2232Z"
      className="fillPrimary200"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.8135 59.95C36.3821 60.5072 35.5408 60.5072 35.1094 59.95L29.3413 52.4997C29.0406 52.1113 29.0406 51.5687 29.3413 51.1803C29.7727 50.6231 30.614 50.6231 31.0454 51.1803L36.8135 58.6307C37.1142 59.0191 37.1142 59.5616 36.8135 59.95Z"
      className="fillPrimary500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.8135 51.1803C37.1142 51.5687 37.1142 52.1113 36.8135 52.4997L31.0454 59.95C30.614 60.5072 29.7727 60.5072 29.3413 59.95C29.0406 59.5617 29.0406 59.0191 29.3413 58.6307L35.1094 51.1803C35.5408 50.6231 36.3821 50.6231 36.8135 51.1803Z"
      className="fillPrimary500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.941 59.95C59.5096 60.5072 58.6682 60.5072 58.2368 59.95L52.4687 52.4997C52.168 52.1113 52.168 51.5687 52.4687 51.1803C52.9001 50.6231 53.7415 50.6231 54.1729 51.1803L59.941 58.6307C60.2417 59.0191 60.2417 59.5616 59.941 59.95Z"
      className="fillPrimary500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.941 51.1803C60.2417 51.5687 60.2417 52.1113 59.941 52.4997L54.1729 59.95C53.7415 60.5072 52.9001 60.5072 52.4687 59.95C52.168 59.5617 52.168 59.0191 52.4687 58.6307L58.2368 51.1803C58.6682 50.6231 59.5096 50.6231 59.941 51.1803Z"
      className="fillPrimary500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.8562 84.2048C57.5497 84.8753 56.6994 85.0858 56.0873 84.6748C48.9461 79.8806 40.4353 79.8806 33.2941 84.6748C32.682 85.0858 31.8317 84.8753 31.5252 84.2048C31.302 83.7165 31.4454 83.1361 31.8836 82.8258C39.8594 77.1778 49.522 77.1778 57.4978 82.8258C57.936 83.1361 58.0794 83.7165 57.8562 84.2048Z"
      className="fillPrimary500"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.7019 0.829993C88.0674 1.13479 88.1754 1.65094 87.9626 2.07666L83.9906 10.0238C83.6947 10.6158 82.9241 10.7769 82.4158 10.3531C82.0503 10.0483 81.9423 9.53212 82.1551 9.1064L86.1271 1.15929C86.423 0.567309 87.1936 0.406166 87.7019 0.829993ZM100.078 2.28507C100.325 2.74195 100.229 3.30908 99.8433 3.65786L87.3975 14.9217C86.8562 15.4116 85.999 15.2652 85.6509 14.6235C85.4031 14.1666 85.4999 13.5995 85.8853 13.2507L98.331 1.9869C98.8724 1.49699 99.7295 1.64332 100.078 2.28507ZM95.5771 16.8099C95.7478 17.3611 95.4988 17.9554 94.9861 18.2203L90.3903 20.5948C89.7316 20.9351 88.9253 20.5973 88.706 19.889C88.5353 19.3377 88.7843 18.7434 89.297 18.4786L93.8928 16.1041C94.5515 15.7638 95.3578 16.1016 95.5771 16.8099Z"
      className="fillPrimary500"
    />
  </S.CustomSvg>
)
