import { WppAccordion } from '@platform-ui-kit/components-library-react'
import { ComponentProps, useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useCatalogueFilters } from 'pages/catalogue/main/CatalogueFiltersContext'
import { CheckboxGroup } from 'ui-base/checkboxGroup/CheckboxGroup'
import { SearchFilterBlock } from 'ui-base/searchFilterBlock/SearchFilterBlock'

import * as S from 'pages/applications/list/ApplicationsListPage.styled'

interface Props<Option, Value> extends Omit<ComponentProps<typeof WppAccordion>, 'onChange'> {
  filterTitle: string
  hasSearch?: boolean
  labelWidth?: number
  options: Option[]
  value: Value[]
  onChange: (newValue: Value[], option: Option) => unknown
}

type BaseOption = Record<string, any>
type BaseValue = string | number

const LABEL_WIDTH = 190
export const ApplicationCheckboxFilter = <Option extends BaseOption, Value extends BaseValue>({
  filterTitle,
  value,
  options,
  onChange,
  hasSearch,
  labelWidth = LABEL_WIDTH,
  ...rest
}: Props<Option, Value>) => {
  const {
    filtersState: { isApplicationFiltersDirty },
  } = useCatalogueFilters()
  const { t } = useTranslation(['applications'])
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    if (!isApplicationFiltersDirty) setSearchTerm('')
  }, [isApplicationFiltersDirty])

  const renderOptions = useMemo(() => {
    if (!searchTerm) return options
    return options.filter(item => item.label.toLowerCase().includes(searchTerm.toLocaleLowerCase()))
  }, [options, searchTerm])
  const emptySearch = searchTerm && !renderOptions.length

  return (
    <SearchFilterBlock
      searchValue={searchTerm}
      onSearchChange={hasSearch ? setSearchTerm : null}
      filterTitle={filterTitle}
      {...rest}
    >
      <S.ScrollableFiltersBlockArea className="scrollableFilters">
        {emptySearch && (
          <S.EmptySearch type="s-body" slot="header">
            {t('applications|filters.filter_empty_search')}
          </S.EmptySearch>
        )}
        <CheckboxGroup showTooltip labelWidth={labelWidth} options={renderOptions} value={value} onChange={onChange} />
      </S.ScrollableFiltersBlockArea>
    </SearchFilterBlock>
  )
}
