import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useMeasure } from 'react-use'

import { MarketplaceSizeContext, MarketplaceSizeState } from 'providers/marketplaceSize/MarketplaceSizeContext'

export const MarketplaceSizeProvider = ({ children }: PropsWithChildren) => {
  const [marketplaceRef, marketplaceMeasure] = useMeasure<HTMLDivElement>()
  const [sizes, setSizes] = useState<MarketplaceSizeState>({ width: 0, height: 0 })

  const marketplaceSizeValue = useMemo(
    () => ({
      width: sizes.width,
      height: sizes.height,
    }),
    [sizes],
  )

  useEffect(() => {
    setSizes({ width: marketplaceMeasure.width, height: marketplaceMeasure.height })
  }, [marketplaceMeasure])

  return (
    <MarketplaceSizeContext.Provider value={marketplaceSizeValue}>
      <div ref={marketplaceRef}>{children}</div>
    </MarketplaceSizeContext.Provider>
  )
}
