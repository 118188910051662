import styled from '@emotion/styled'
import { WppCheckbox } from '@platform-ui-kit/components-library-react'

export const ListItem = styled('li', { shouldForwardProp: prop => prop !== 'marginTop' })<{
  marginTop?: number
}>`
  & + & {
    margin-top: ${props => `${props.marginTop || 6}px`};
  }
`

export const Checkbox = styled(WppCheckbox, { shouldForwardProp: prop => prop !== 'labelWidth' })<{
  labelWidth: number
}>`
  ::part(text) {
    max-width: ${({ labelWidth }) => labelWidth}px;
    white-space: nowrap;
  }
`

export const ShowMore = styled('span')`
  &:hover {
    cursor: pointer;
  }
`
