import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import i18n, { InitOptions } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { marketplacePublicUrl } from 'app-config'

dayjs.extend(localeData)
dayjs.extend(localizedFormat)

export const defaultLng = 'en'
export const defaultNS = 'common'
export const nsSeparator = '|'

export const createI18nConfig = (lng?: string): InitOptions => ({
  ...(lng ? { lng } : {}),
  fallbackLng: defaultLng,
  ns: [],
  defaultNS,
  fallbackNS: defaultNS,
  nsSeparator,
  load: 'languageOnly',
  backend: {
    loadPath: `${marketplacePublicUrl}locales/{{lng}}/{{ns}}.json`,
  },
})

export const createi18n = (lng?: string) =>
  i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(createI18nConfig(lng))
export default i18n
