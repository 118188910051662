import { fetchApplicationsApi } from 'api/applications/fetchers/fetchApplicationsApi'
import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useApplicationsQuickSearchApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.APPLICATIONS_QUICK_SEARCH,
  fetcher: fetchApplicationsApi,
  selector: res => res?.pages?.flatMap(page => page.data?.content) || [],
  getNextPageParam: lastPage => (lastPage?.data?.last ? undefined : lastPage?.data?.number + 1),
})
