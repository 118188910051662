import { WppIconFavourites, WppIconFavouritesFilled } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useLocale } from 'hooks/useLocale'
import { ApplicationDTO } from 'types/applications/application'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'ui-shared/applicationCard/ApplicationCard.styled'

type Props = Pick<ApplicationDTO, 'reviewCount' | 'overallReviewScore'> & {
  isScoreLoading?: boolean
  isHeader?: boolean
}

export const ApplicationCardReviews = ({ reviewCount, overallReviewScore, isScoreLoading, isHeader }: Props) => {
  const { t } = useTranslation(['common', 'applications'])
  const { numberLocale } = useLocale()

  const { noReviews, hasReviews } = {
    noReviews: {
      title: t(isHeader ? 'applications|application_reviews.be_the_first' : 'applications|no_reviews'),
      iconColor: 'var(--wpp-grey-color-600)',
    },
    hasReviews: {
      title: isHeader
        ? t('common|one_digit_number', { value: overallReviewScore, lng: numberLocale })
        : `${t('common|one_digit_number', { value: overallReviewScore, lng: numberLocale })}
         (${t('common|formatted_number', { value: reviewCount, lng: numberLocale })})`,
      iconColor: '#F15619',
    },
  }

  return (
    <Flex gap={4} align="center" data-testid="application-review">
      {isHeader && !reviewCount && !overallReviewScore ? (
        <WppIconFavourites size="m" color="var(--wpp-grey-color-1000)" />
      ) : (
        <WppIconFavouritesFilled
          size="m"
          color={!!reviewCount && !!overallReviewScore ? hasReviews.iconColor : noReviews.iconColor}
        />
      )}
      {isScoreLoading ? (
        <S.Spinner size="s" />
      ) : (
        <S.ApplicationReviews type="xs-body">
          {!!reviewCount && !!overallReviewScore ? hasReviews.title : noReviews.title}
        </S.ApplicationReviews>
      )}
    </Flex>
  )
}
