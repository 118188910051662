import { prepareApplication } from 'api/applications/common'
import { fetchApplicationsApi } from 'api/applications/fetchers/fetchApplicationsApi'
import { createUseInfiniteQuery } from 'api/common/createUseInfiniteQuery'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useInfiniteApplicationsApi = createUseInfiniteQuery({
  queryKey: ApiQueryKeys.APPLICATIONS_INFINITE,
  fetcher: fetchApplicationsApi,
  selector: res =>
    res?.pages?.flatMap(page => page.data?.content.map(application => prepareApplication(application))) || [],
  getNextPageParam: lastPage => (lastPage?.data?.last ? undefined : lastPage?.data?.number + 1),
})
