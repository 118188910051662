import { UserDetails, Tenant } from '@wpp-open/core'
import { createContext } from 'react'

export type UserDataContextValue = {
  isAuthenticated: boolean
  getAccessToken: () => string | undefined
  userDetails: UserDetails
  tenant?: Tenant
  logout?: () => Promise<any>
}

export const UserDataContext = createContext<UserDataContextValue>(null!)
