export enum ApiQueryKeys {
  APPLICATION = 'application',
  APPLICATION_RATING = 'application/rating',
  APPLICATION_REVIEW = 'application/review',
  APPLICATION_REVIEWS = 'application/reviews',
  APPLICATION_REVIEWS_PREVIEW = 'application/reviews/preview',
  APPLICATION_PREVIEW_RATED_REVIEW = 'application/reviews/preview-rated',
  APPLICATIONS_INFINITE = 'applications/search',
  APPLICATIONS_QUICK_SEARCH = 'applications/quick-search',
  APPLICATIONS_RELATED = 'applications/related',
  APPLICATIONS_TENANTS = 'application/tenants',
  BLUEPRINTS = 'catalogue/blueprints',
  COLLECTIONS = 'collections',
  COLLECTIONS_APPLICATION = 'collections/application',
  COLLECTION = 'collection',
  COLLECTION_INFINITE = 'collection/infinite',
  MARKETS = 'markets',
  DOWNLOAD_URLS = 'download-urls',
  DOWNLOAD_AVATARS = 'download-avatars',
  DOWNLOAD_AGENCY_URLS = 'download-agency-urls',
  AGENCIES = 'agencies',
  AGENCY_PRODUCT_COUNTS = 'agency-product-counts',
  AGENCY = 'agency',
  AGENCY_EXTERNAL = 'agency-external',
  USER_PERMISSIONS = 'user-permissions',
  TENANT = 'tenant',
  TENANTS = 'tenants',
}
