import { PropsWithChildren } from 'react'
import { useMeasure } from 'react-use'

import { useOnScreen } from 'hooks/useOnScreen'

import * as S from 'ui-shared/catalogueIntroSection/CatalogueIntroSection.styled'

export const CatalogueSearch = ({ children }: PropsWithChildren) => {
  const { isIntersecting, ref: searchPlaceholderRef } = useOnScreen(true)
  const [searchRef] = useMeasure<HTMLDivElement>()

  return (
    <>
      <S.SearchPlaceholder ref={searchPlaceholderRef} />
      <S.SearchWrapper ref={searchRef} isCollapsed={!isIntersecting}>
        {children}
      </S.SearchWrapper>
    </>
  )
}
