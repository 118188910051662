import dayjs from 'dayjs'
import { PropsWithChildren, useEffect } from 'react'

import i18n, { createi18n } from 'lib/i18n'
import { LocalisationContext, LocalisationContextValue } from 'providers/localisation/LocalisationContext'

createi18n()

const defaultLocale = {
  dateLocale: 'en',
  displayLanguage: 'en',
  numberLocale: 'en',
}

export const LocalisationProvider = ({
  children,
  locale,
}: PropsWithChildren<{ locale?: LocalisationContextValue }>) => {
  useEffect(() => {
    i18n.changeLanguage(locale?.displayLanguage || defaultLocale.displayLanguage)
    dayjs.locale(locale?.dateLocale || defaultLocale.dateLocale)
  }, [locale])

  return (
    <LocalisationContext.Provider
      value={{
        dateLocale: locale?.dateLocale || defaultLocale.dateLocale,
        displayLanguage: locale?.displayLanguage || defaultLocale.displayLanguage,
        numberLocale: locale?.numberLocale || defaultLocale.numberLocale,
      }}
    >
      {children}
    </LocalisationContext.Provider>
  )
}
