import { WppSpinner } from '@platform-ui-kit/components-library-react'
import { HTMLAttributes, useEffect, useState } from 'react'

import * as S from 'ui-base/spinner/Spinner.styled'

export type SpinnerVariant = 'block' | 'inline'

interface Props extends HTMLAttributes<HTMLDivElement> {
  delay?: number
  variant?: SpinnerVariant
  size?: 's' | 'm' | 'l'
}

//TODO: Afer CL updating + adding grid we should replace 'CustomWppSpinner' with 'WppSpinner' everywhere and remove loader component.
export const CustomWppSpinner = ({ delay, variant = 'block', size = 's', ...rest }: Props) => {
  const [isShown, setIsShown] = useState(false)
  useEffect(() => {
    if (!delay) return
    const timer = setTimeout(() => setIsShown(true), delay)
    return () => {
      clearTimeout(timer)
    }
  }, [delay])

  if (delay && !isShown) return null
  return (
    <S.Spinner variant={variant} {...rest}>
      <WppSpinner size={size} />
    </S.Spinner>
  )
}

export { WppSpinner }
