import { InfiniteQueryObserverBaseResult } from '@tanstack/query-core'
import { useEffect } from 'react'

interface Props {
  loadMoreRef: HTMLElement
  isFetchingNextPage: boolean
  fetchNextPage: InfiniteQueryObserverBaseResult['fetchNextPage']
  hasNextPage?: boolean
  isDisabled?: boolean
}

export const useInfiniteFetchNextPage = ({
  loadMoreRef,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  isDisabled,
}: Props) => {
  useEffect(() => {
    if (!hasNextPage || !loadMoreRef || isDisabled) return
    const observer = new IntersectionObserver(entries =>
      entries.forEach(entry => entry.isIntersecting && !isFetchingNextPage && fetchNextPage()),
    )

    observer.observe(loadMoreRef)
    return () => {
      observer.disconnect()
    }
  }, [loadMoreRef, hasNextPage, fetchNextPage, isFetchingNextPage, isDisabled])
}
