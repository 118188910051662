import styled from '@emotion/styled'

import { Flex } from 'ui-base/flex/Flex'

export const Wrapper = styled(Flex)`
  position: relative;
  padding: 0;
  max-width: 1920px;
  width: 100%;
  margin: 8px auto 0;
  min-height: calc(100vh - 64px - 64px);
`
