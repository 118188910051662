import { WppGrid } from '@platform-ui-kit/components-library-react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ApplicationsListPage } from 'pages/applications/list/ApplicationsListPage'
import { CatalogueFilters } from 'pages/catalogue/main/CatalogueFilters'

import * as S from 'pages/catalogue/main/CatalogueMainPage.styled'

export enum CatalogueRoutes {
  APPLICATIONS = 'applications/*',
}

export const CatalogueMainPage = () => (
  <S.CatalogueGrid container rowSpacing={4}>
    <WppGrid item all={6} data-testid="filters-block">
      <CatalogueFilters />
    </WppGrid>
    <WppGrid item all={18}>
      <Routes>
        <Route path={CatalogueRoutes.APPLICATIONS} element={<ApplicationsListPage />} />
        <Route path="*" element={<Navigate to={'applications'} replace />} />
      </Routes>
    </WppGrid>
  </S.CatalogueGrid>
)
